import Vue from "vue";
/**
 * Подключение Sentry
 */
import * as Sentry from "@sentry/vue";
import { BrowserTracing } from "@sentry/tracing";

import VueMask, { VueMaskDirective } from "v-mask";

import vuetify from "@/shared/plugins/vuetify";
import router from "@/shared/router";
import store from "@/shared/store";
import App from "@/app/App.vue";
/**
 * Динамическая обертка страниц
 */
import AppLayout from "@/app/layouts/AppLayout";
import "@/scss/main.scss";

const AuthLayout = () => import("@/app/layouts/AuthLayout");
const DefaultLayout = () => import("@/app/layouts/DefaultLayout");

if (process.env.VUE_APP_ENVIRONMENT !== "development") {
  Sentry.init({
    Vue,
    environment: process.env.VUE_APP_ENVIRONMENT,
    release: `${process.env.VUE_APP_NAME}@${process.env.VUE_APP_VERSION}`,
    dsn: process.env.VUE_APP_SENTRY_DSN,
    integrations: [
      new BrowserTracing({
        tracingOrigins: [process.env.VUE_APP_API_URL, /^\//],
      }),
    ],
    debug: process.env.VUE_APP_ENVIRONMENT !== "production",
    tracesSampleRate:
      process.env.VUE_APP_ENVIRONMENT === "production" ? 0.2 : 1,
    tracingOptions: {
      trackComponents: true,
    },
    // Vue specific
    logErrors: process.env.VUE_APP_ENVIRONMENT !== "production",
    attachProps: true,
    attachStacktrace: true,
  });
}

Vue.component("AppLayout", AppLayout);
Vue.directive("mask", VueMaskDirective);
Vue.use(VueMask);

Vue.component("AuthLayout", AuthLayout);
Vue.component("DefaultLayout", DefaultLayout);

Vue.config.productionTip = false;

new Vue({
  vuetify,
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
