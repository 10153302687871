import Service from "@/shared/api/Service";

const resource = "/ensured";

export default {
  /**
   * Детальная информация по застрахованному
   * @param id
   * @returns {Promise<AxiosResponse<any>>}
   */
  getEnsured(id) {
    return Service.get(`${resource}/${id}`);
  },
  /**
   * Список застрахованных
   * @param params
   * @returns {Promise<AxiosResponse<any>>}
   */
  getInsured(params) {
    return Service.get(`${resource}`, params);
  },
  getEnsuredMedicalExaminations(id) {
    return Service.get(`${resource}/${id}/medical-examinations`);
  },
  getEnsuredConsultations(id) {
    return Service.get(`${resource}/${id}/consultations`);
  },
  getEnsuredDisabilityPeriods(id) {
    return Service.get(`${resource}/${id}/disability-periods`);
  },
  createFocusGroupForInsured(payload) {
    return Service.post(`${resource}/${payload.id}/focus-groups`, payload.data);
  },
  deleteFocusGroup(payload) {
    return Service.delete(
      `${resource}/${payload.employee}/focus-groups/${payload.id}`
    );
  },
  updateComment(payload) {
    return Service.put(
      `${resource}/${payload.params.employee}/comment`,
      payload.data
    );
  },
  prepareExportInsuredWithAllFields(params) {
    return Service.post(`${resource}/export/all_fields`, null, params);
  },
  prepareExportInsuredWithParticalFields(params) {
    return Service.post(`${resource}/export/partical_fields`, null, params);
  },
  exportInsured(payload) {
    return Service.get(
      `${resource}/export/${payload.id}/download`,
      payload.data
    );
  },
  updateMedicalExaminationData(payload) {
    return Service.put(
      `${resource}/${payload.params.medicalExamination}/medical-examinations/${payload.params.employee}`,
      payload.data
    );
  },
  updateDisabilityPeriodsComment(payload) {
    return Service.put(
      `${resource}/${payload.params.employee}/disability-comment`,
      payload.data
    );
  },
};
