export function formatOrganizationsStructure(data, level) {
  data.forEach((organisation) => {
    if (organisation.open === true) {
      organisation.isExpand = true;
    } else {
      organisation.isExpand = false;
    }
    organisation.isAdvisorsExpand = false;
    organisation.children = organisation.children || [];
    organisation.level = level;
    organisation.bLeft = level * 16;
    if (organisation.children.length) {
      formatOrganizationsStructure(organisation.children, level + 1);
    }
  });
}
