import Service from "@/shared/api/Service";

const resource = "/medical-advisor/call-attempts";

export default {
  getCallStatuses(params) {
    return Service.get(`/call-attempt/statuses`, params);
  },
  setStatusRefused(payload) {
    return Service.post(
      `${resource}/${payload.callAttempt}/set-status/rejection`
    );
  },

  setStatusRescheduled(params) {
    return Service.post(
      `${resource}/${params.callAttempt}/set-status/postponed`,
      params.data
    );
  },

  setStatusFailedToCall(params) {
    return Service.post(
      `${resource}/${params.callAttempt}/set-status/unsuccessfully`,
      params.data
    );
  },
  createFirstCallAttempt(params) {
    return Service.post(
      `ensured/${params.employeeId}/call-attempt`,
      params.data
    );
  },
};
