import qs from "qs";
import { saveAs } from "file-saver";
import "moment/locale/ru";

import MedicalAdvisorsRepository from "@/shared/api/Repositories/MedicalAdvisorsRepository";
import EnsuredRepository from "@/shared/api/Repositories/EnsuredRepository";
import FocusGroupsRepository from "@/shared/api/Repositories/FocusGroupsRepository";
import OrganisationsRepository from "@/shared/api/Repositories/OrganisationsRepository";
import AdvisorOrganisationsRepository from "@/shared/api/Repositories/advisor/AdvisorOrganisationsRepository";
import AsyncTasksRepository from "@/shared/api/Repositories/AsyncTasksRepository";
import { filterOrganisationsTreeview } from "../../../helper/filterOrganisationsTreeview";

const state = () => ({
  insured: {},
  dataDiagnosesModel: false,
  formationFocusGroupModal: false,
  diagnosesStatistic: {},
  firedEmployeModal: false,
  focusGroup: {},
  searchOrganisations: {},
  flatSearchOrganisations: {},
});

const getters = {
  insured: (state) => state.insured,
  dataDiagnosesModel: (state) => state.dataDiagnosesModel,
  formationFocusGroupModal: (state) => state.formationFocusGroupModal,
  diagnosesStatistic: (state) => state.diagnosesStatistic,
  firedEmployeModal: (state) => state.firedEmployeModal,
  focusGroup: (state) => state.focusGroup,
  searchOrganisations: (state) => state.searchOrganisations,

  flatSearchOrganisations: (state) => state.flatSearchOrganisations,
};

const mutations = {
  setInsured(state, payload) {
    if (payload.reset) {
      state.insured = payload.data;
    } else {
      state.insured = {
        ...payload.data,
        items: [...state.insured.items, ...payload.data.items],
      };
    }
  },
  setDiagnosesStatistic(state, payload) {
    state.diagnosesStatistic = payload;
  },
  setDataDiagnosesModal(state, payload) {
    state.dataDiagnosesModel = payload;
  },
  setFormationFocusGroupModal(state, payload) {
    state.formationFocusGroupModal = payload;
  },
  setFiredEmployeModal(state, payload) {
    state.firedEmployeModal = payload;
  },

  setFiredEmployeeRow(state, index) {
    state.insured = {
      ...state.insured,
      items: state.insured.items.filter(
        (item, itemIndex) => itemIndex !== index
      ),
      total: state.insured.total - 1,
    };
  },
  setSearchOrganizations(state, payload) {
    if (payload.reset) {
      state.searchOrganisations = payload.data;
    } else {
      state.searchOrganisations = {
        ...payload.data,
        items: [...state.searchOrganisations.items, ...payload.data.items],
      };
    }
  },
  setFocusGroup(state, payload) {
    if (payload.reset) {
      state.focusGroup = payload.data;
    } else {
      state.focusGroup = {
        ...payload.data,
        items: [...state.focusGroup.items, ...payload.data.items],
      };
    }
  },
  setFlatSearchOrganizations(state, payload) {
    if (payload.reset) {
      state.flatSearchOrganisations = payload.data;
    } else {
      state.flatSearchOrganisations = {
        ...payload.data,
        items: [...state.flatSearchOrganisations.items, ...payload.data.items],
      };
    }
  },
};

const actions = {
  getInsured(context, payload) {
    const params = {
      params: payload ? payload.params : null,
      paramsSerializer: (params) => qs.stringify(params, { encode: false }),
    };
    return new Promise((resolve, reject) => {
      EnsuredRepository.getInsured(params)
        .then((res) => {
          context.commit("setInsured", {
            data: res.data.data,
            reset: payload.reset,
          });
          resolve(res.data.data);
        })
        .catch((error) => reject(error));
    });
  },
  getDiagnosesStatistic(context, payload) {
    return new Promise((resolve, reject) => {
      OrganisationsRepository.getDiagnosesStatistic({ id: payload.id })
        .then((res) => {
          context.commit("setDiagnosesStatistic", res.data.data);
          resolve(res.data.data);
        })
        .catch((error) => reject(error));
    });
  },
  createFocusGroup(context, payload) {
    return new Promise((resolve, reject) => {
      FocusGroupsRepository.createFocusGroup(payload)
        .then((res) => {
          context.commit("setFormationFocusGroupModal", false);
          resolve(res.data.data);
        })
        .catch((error) => reject(error));
    });
  },
  editFocusGroup(context, payload) {
    return new Promise((resolve, reject) => {
      FocusGroupsRepository.editFocusGroup(payload)
        .then((res) => {
          resolve(res.data.data);
        })
        .catch((error) => reject(error));
    });
  },
  prepareExportInsuredWithAllFields(context, payload) {
    return new Promise((resolve, reject) => {
      const params = {
        params: payload ? payload : null,
        paramsSerializer: (params) => qs.stringify(params, { encode: false }),
      };
      EnsuredRepository.prepareExportInsuredWithAllFields(params)
        .then((res) => {
          resolve(res.data.data);
        })
        .catch((error) => reject(error));
    });
  },
  prepareExportInsuredWithParticalFields(context, payload) {
    return new Promise((resolve, reject) => {
      const params = {
        params: payload ? payload : null,
        paramsSerializer: (params) => qs.stringify(params, { encode: false }),
      };
      EnsuredRepository.prepareExportInsuredWithParticalFields(params)
        .then((res) => {
          resolve(res.data.data);
        })
        .catch((error) => reject(error));
    });
  },
  exportInsured(context, payload) {
    return new Promise((resolve, reject) => {
      AsyncTasksRepository.exportFile({
        data: { responseType: "arraybuffer" },
        id: payload.id,
      })
        .then((res) => {
          this.blob = new Blob([res.data], {
            type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;",
          });
          const fileName = `Список застрахованных.xlsx`;
          saveAs(this.blob, fileName);
          resolve(res.data);
        })
        .catch((err) => reject(err));
    });
  },
  firedEmployee(context, payload) {
    return new Promise((resolve, reject) => {
      OrganisationsRepository.firedEmployee(payload)
        .then((res) => {
          context.commit("setFiredEmployeModal", false);
          resolve(res.data.data);
        })
        .catch((error) => reject(error));
    });
  },

  unFiredEmployee(context, payload) {
    return new Promise((resolve, reject) => {
      OrganisationsRepository.unFiredEmployee(payload)
        .then((res) => {
          context.commit("setFiredEmployeModal", false);
          resolve(res.data.data);
        })
        .catch((error) => reject(error));
    });
  },
  getSearchOrganisations(context, payload) {
    const params = {
      params: payload ? payload.params : null,
      paramsSerializer: (params) => qs.stringify(params, { encode: false }),
    };
    return new Promise((resolve, reject) => {
      AdvisorOrganisationsRepository.searchOrganisations(params)
        .then((res) => {
          if (payload?.isSearch) {
            const expandedItems = [];
            const newOrgs = res.data.data.items.map((organisation) => {
              return filterOrganisationsTreeview(organisation, expandedItems);
            });
            context.commit("setSearchOrganizations", {
              data: { ...res.data.data, items: newOrgs },
              reset: payload.reset,
            });
            resolve(expandedItems);
          } else {
            context.commit("setSearchOrganizations", {
              data: res.data.data,
              reset: payload.reset,
            });
            resolve();
          }
        })
        .catch((error) => reject(error));
    });
  },
  getSearchFlatOrganisations(context, payload) {
    const params = {
      params: payload ? payload.params : null,
      paramsSerializer: (params) => qs.stringify(params, { encode: false }),
    };
    return new Promise((resolve, reject) => {
      AdvisorOrganisationsRepository.seacrhFlatOrganisations(params)
        .then((res) => {
          context.commit("setFlatSearchOrganizations", {
            data: res.data.data,
            reset: payload.reset,
          });
          resolve(res.data.data);
        })
        .catch((error) => reject(error));
    });
  },
  deleteFocusGroup(context, payload) {
    return new Promise((resolve, reject) => {
      FocusGroupsRepository.deleteFocusGroup(payload)
        .then((res) => {
          resolve(res.data.data);
        })
        .catch((error) => reject(error));
    });
  },
  checkFocusGroup(context, payload) {
    return new Promise((resolve, reject) => {
      FocusGroupsRepository.checkFocusGroup(payload)
        .then((res) => {
          resolve(res.data.data);
        })
        .catch((error) => reject(error));
    });
  },
  getFocusGroup(context, payload) {
    const params = {
      params: payload ? payload.params : null,
      paramsSerializer: (params) => qs.stringify(params, { encode: false }),
    };
    return new Promise((resolve, reject) => {
      MedicalAdvisorsRepository.getFocusGroup(params)
        .then((res) => {
          const formattedFocusGroups = res.data.data.items.map((focusGroup) => {
            return {
              ...focusGroup,
              name:
                focusGroup.name.charAt(0).toUpperCase() +
                focusGroup.name.slice(1),
            };
          });
          context.commit("setFocusGroup", {
            data: {
              ...res.data.data,
              items: formattedFocusGroups,
            },
            reset: payload.reset,
          });
          resolve(res.data.data);
        })
        .catch((error) => reject(error));
    });
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
