import Service from "@/shared/api/Service";

const resource = "/admin/organisations";
export default {
  getOrganizations(params) {
    return Service.get(`${resource}`, params);
  },
  getOrganization(id, params) {
    return Service.get(`${resource}/${id}`, params);
  },
  getAdvisors(id, params) {
    return Service.get(`${resource}/${id}/medical-advisors`, params);
  },
  getEmployees(id, params) {
    return Service.get(`${resource}/${id}/employees`, params);
  },
  firedEmployee(payload) {
    return Service.post(
      `${resource}/${payload.orgId}/employees/${payload.employeeId}/fired`
    );
  },
  unfiredEmployee(payload) {
    return Service.post(
      `${resource}/${payload.orgId}/employees/${payload.employeeId}/unfired`
    );
  },
  attachAdvisors(payload) {
    return Service.post(
      `${resource}/${payload.orgId}/medical-advisors/attach`,
      payload.data
    );
  },
  addOrganization(payload) {
    return Service.post(`${resource}/`, payload.fd);
  },
  updateOrganization(payload) {
    return Service.put(`${resource}/${payload.orgId}`, payload.data);
  },
  createResponsible(payload) {
    return Service.post(`${resource}/${payload.id}/responsible`, payload.data);
  },
  detachAdvisor(payload) {
    return Service.post(
      `${resource}/${payload.orgId}/medical-advisors/${payload.advisorId}/detach`
    );
  },
  detachAdvisorRecursive(payload) {
    return Service.post(
      `${resource}/${payload.orgId}/medical-advisors/${payload.advisorId}/detach-recursive`
    );
  },
  uploadDisabilityReport(id, data) {
    return Service.post(`${resource}/${id}/disability-periods/import`, data);
  },
  uploadEmployees(id, data) {
    return Service.post(`${resource}/${id}/employees`, data);
  },
  createOrganizationStructure(payload) {
    return Service.post(`${resource}/${payload.orgId}`, payload.data);
  },
  deleteOrganizationStructure(payload) {
    return Service.delete(`${resource}/${payload.orgId}`);
  },
  prepareExportStructure(payload) {
    return Service.post(`${resource}/${payload.orgId}/export`);
  },
  uploadTransferEmployeesReport(payload) {
    return Service.post(
      `${resource}/${payload.orgId}/move-employees`,
      payload.data
    );
  },
  getSearchOrganizations(params) {
    return Service.get(`${resource}/search`, params);
  },
};
