import qs from "qs";
import { saveAs } from "file-saver";

import InsuredRepository from "@/shared/api/Repositories/partner/PartnerInsuredRepository";
import PartnerReports from "@/shared/api/Repositories/partner/PartnerReportsRepository";
import TemplatesRepository from "@/shared/api/Repositories/TemplatesRepository";
import LinksRepository from "@/shared/api/Repositories/LinksRepository";

const state = () => ({
  insuredList: {},
  insuredForReport: {},
  modalReportWrapper: false,
  getInfoModal: true,
  displayInfoModal: false,
  uploadReportModal: false,
  linkId: null,
  link: null,
});

const getters = {
  insuredList: (state) => state.insuredList,
  insuredForReport: (state) => state.insuredForReport,
  modalReportWrapper: (state) => state.modalReportWrapper,
  getInfoModal: (state) => state.getInfoModal,
  displayInfoModal: (state) => state.displayInfoModal,
  uploadReportModal: (state) => state.uploadReportModal,
  linkId: (state) => state.linkId,
  link: (state) => state.link,
};

const mutations = {
  setInsuredList(state, payload) {
    if (payload.reset) {
      state.insuredList = payload.data;
    } else {
      state.insuredList = {
        ...payload.data,
        items: [...state.insuredList.items, ...payload.data.items],
      };
    }
  },
  setInsuredForReport(state, payload) {
    if (payload.reset) {
      state.insuredForReport = payload.data;
    } else {
      state.insuredForReport = {
        ...payload.data,
        items: [...state.insuredForReport.items, ...payload.data.items],
      };
    }
  },
  setModalReportWrapper(state, payload) {
    state.modalReportWrapper = payload;
    if (!payload) {
      state.getInfoModal = true;
      state.displayInfoModal = false;
      state.uploadReportModal = false;
    }
  },
  setDIsplayInfoModal(state, payload) {
    state.getInfoModal = !payload;
    state.displayInfoModal = payload;
  },
  setUploadReportModal(state, payload) {
    state.getInfoModal = !payload;
    state.displayInfoModal = !payload;
    state.uploadReportModal = payload;
  },
  updateLinkId(state, payload) {
    state.linkId = payload;
  },
  updateLink(state, payload) {
    state.link = payload;
  },
};

const actions = {
  getInsured(context, payload) {
    const params = {
      params: payload ? payload.params : null,
      paramsSerializer: (params) => qs.stringify(params, { encode: false }),
    };
    return new Promise((resolve, reject) => {
      InsuredRepository.insuredList(params)
        .then((res) => {
          context.commit("setInsuredList", {
            data: res.data.data,
            reset: payload.reset,
          });
          resolve(res.data.data);
        })
        .catch((error) => reject(error));
    });
  },
  getInfoFromLink(context, payload) {
    const params = {
      params: payload ? payload.params : null,
      paramsSerializer: (params) => qs.stringify(params, { encode: false }),
    };
    return new Promise((resolve, reject) => {
      LinksRepository.getInfoFromLink(payload.id, params)
        .then((res) => {
          context.commit("setInsuredForReport", {
            data: res.data.data,
            reset: payload.reset,
          });
          resolve(res.data.data);
        })
        .catch((error) => reject(error));
    });
  },
  uploadReport(context, payload) {
    return new Promise((resolve, reject) => {
      PartnerReports.uploadReport(payload.fd)
        .then((res) => resolve(res.data.data))
        .catch((error) => reject(error));
    });
  },
  getTemplatePartnerReport(context, payload) {
    return new Promise((resolve, reject) => {
      TemplatesRepository.getTemplatePartnerReport(payload.id, {
        responseType: "arraybuffer",
      })
        .then((res) => {
          this.blob = new Blob([res.data], {
            type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;",
          });
          saveAs(this.blob, "Шаблон отчета партнера.xlsx");
        })
        .catch((error) => reject(error));
    });
  },
  getRules(context, payload) {
    return new Promise((resolve, reject) => {
      TemplatesRepository.getRules(payload.id, {
        responseType: "arraybuffer",
      })
        .then((res) => {
          this.blob = new Blob([res.data], {
            type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;",
          });
          saveAs(this.blob, "Правила заполнения полей партнера.xlsx");
        })
        .catch((error) => reject(error));
    });
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
