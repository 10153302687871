import Service from "@/shared/api/Service";

const resource = "/partners";

export default {
  getPartners(params) {
    return Service.get(`${resource}`, params);
  },
  blockPartner(payload) {
    return Service.post(`${resource}/${payload.partnerId}/block`);
  },
  unBlockPartner(payload) {
    return Service.post(`${resource}/${payload.partnerId}/unlock`);
  },
  addPartner(params) {
    return Service.post(`${resource}`, params);
  },
};
