import Service from "@/shared/api/Service";

const resource = "/admin/report-forms";
export default {
  getReportsForm(params) {
    return Service.get(`${resource}`, params);
  },
  getReportForm(id) {
    return Service.get(`${resource}/${id}`);
  },
  createReportForm(payload) {
    return Service.post(`${resource}`, payload);
  },
  editReportForm(payload) {
    return Service.put(`${resource}/${payload.id}`, payload.data);
  },
  checkReportFormName(payload) {
    return Service.post(`${resource}/check`, payload);
  },
};
