import AdvisorPartnersRepository from "@/shared/api/Repositories/advisor/AdvisorPartnersRepository";

import qs from "qs";

const state = () => ({
  partnersForAdvisor: {},
});

const getters = {
  partnersForAdvisor: (state) => state.partnersForAdvisor,
};

const mutations = {
  setPartnersForAdvisor(state, payload) {
    if (payload.reset) {
      state.partnersForAdvisor = payload.data;
    } else {
      state.partnersForAdvisor = {
        ...payload.data,
        items: [...state.partnersForAdvisor.items, ...payload.data.items],
      };
    }
  },
  setReportDetail(state, payload) {
    state.reportDetail = payload;
  },
  setItemsInfo(state, payload) {
    state.itemsInfo = payload;
  },
  setExpandOrgs(state, index) {
    const checkItems = [...state.partnersForAdvisor.items];
    checkItems[index] = {
      ...checkItems[index],
      expandOrgs: !checkItems[index].expandOrgs,
    };
    state.partnersForAdvisor = {
      ...state.partnersForAdvisor,
      items: checkItems,
    };
  },
};

const actions = {
  getPartnersForAdvisor(context, payload) {
    const params = {
      params: payload ? payload.params : null,
      paramsSerializer: (params) => qs.stringify(params, { encode: false }),
    };
    return new Promise((resolve, reject) => {
      AdvisorPartnersRepository.getPartners(params)
        .then((res) => {
          context.commit("setPartnersForAdvisor", {
            data: res.data.data,
            reset: payload.reset,
          });
          resolve(res.data.data);
        })
        .catch((error) => reject(error));
    });
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
