import Vue from "vue";
import qs from "qs";

import PartnerReportsRepository from "@/shared/api/Repositories/partner/PartnerReportsRepository";
import DiagnosesRepository from "@/shared/api/Repositories/DiagnosesRepository";

import dateHelper from "@/shared/helper/moment";

const state = () => ({
  reportDetail: {},
  diagnoses: {},
});

const getters = {
  reportDetail: (state) => state.reportDetail,
  diagnoses: (state) => state.diagnoses,
};

const mutations = {
  setReportDetail(state, payload) {
    const { reset, data } = payload;
    if (reset) {
      state.reportDetail = data;
    } else {
      state.reportDetail = {
        ...data,
        items: [...state.reportDetail.items, ...data.items],
      };
    }
  },
  setEditReport(state, payload) {
    let checkArray = [...state.reportDetail.items];
    Vue.set(checkArray[payload.index], payload.key, payload.value);
    state.reportDetail = {
      ...state.reportDetail,
      items: checkArray,
    };
  },
  setDiagnoses(state, payload) {
    if (payload.reset) {
      state.diagnoses = {
        ...payload.data,
        items: payload.data.items.map((item) => {
          let newItem = {
            text: `${item.code} ${item.name}`,
            value: item.id,
          };
          return newItem;
        }),
      };
    } else {
      state.diagnoses = {
        ...state.diagnoses,
        items: [
          ...state.diagnoses.items,
          ...payload.data.items.map((item) => {
            let newItem = {
              text: `${item.code} ${item.name}`,
              value: item.id,
            };
            return newItem;
          }),
        ],
      };
    }
  },
};

const actions = {
  getReportDetail(context, payload) {
    const params = {
      params: payload ? payload.params : null,
      paramsSerializer: (params) => qs.stringify(params, { encode: false }),
    };
    return new Promise((resolve, reject) => {
      PartnerReportsRepository.getReportDetail(payload.id, params)
        .then((res) => {
          const prepareDateFields = (item, key) => {
            return (
              item.data?.[key].map((field) => ({
                value: field.id,
                text: `${field.code} ${field.name}`,
              })) || []
            );
          };
          const prepareSelectedOption = (item, key) => {
            const option = item.data?.[key];
            return option
              ? option.charAt(0).toUpperCase() + option.slice(1)
              : "";
          };
          const indicators = [
            ...res.data.data.items.map((item) => ({
              ...item.data,
              ...item.employee,
              employee_id: item.employee_id,
              id: item.id,
              birth_date: dateHelper.dateFormat(item.employee.birth_date),
              examination_date: item.date_of_examination,
              anamnesis: prepareDateFields(item, "anamnesis"),
              burdened_heredity: prepareDateFields(item, "burdened_heredity"),
              examination_result: prepareDateFields(item, "examination_result"),
              smocking: prepareSelectedOption(item, "smocking"),
              low_physical_activity: prepareSelectedOption(
                item,
                "low_physical_activity"
              ),
              poor_diet: prepareSelectedOption(item, "poor_diet"),
              alcohol: prepareSelectedOption(item, "alcohol"),
              drugs: prepareSelectedOption(item, "drugs"),
              stress: prepareSelectedOption(item, "stress"),
              fluorography: prepareSelectedOption(item, "fluorography"),
              electrocardiogram: prepareSelectedOption(
                item,
                "electrocardiogram"
              ),
              intraocular_pressure: prepareSelectedOption(
                item,
                "intraocular_pressure"
              ),
            })),
          ];
          const reportForm = res.data.data.report.report_form;
          const customHeaders = reportForm?.custom_parameters
            .filter((param) => param.active)
            .map((parameter) => {
              return {
                text: parameter.name,
                value: parameter.server_name,
                sortable: false,
              };
            });
          context.commit("setReportDetail", {
            reset: payload.reset,
            data: {
              ...res.data.data,
              items: indicators,
              customHeaders: customHeaders,
            },
          });
          resolve(res.data.data);
        })
        .catch((error) => reject(error));
    });
  },
  saveAndResendReport(context, payload) {
    return new Promise((resolve, reject) => {
      PartnerReportsRepository.saveAndResendReport(payload.id, {
        examinations: payload.data,
      })
        .then((res) => resolve(res.data.data))
        .catch((error) => reject(error));
    });
  },
  getDiagnoses(context, payload) {
    const params = {
      params: payload ? payload.params : null,
      paramsSerializer: (params) => qs.stringify(params, { encode: false }),
    };
    return new Promise((resolve, reject) => {
      DiagnosesRepository.getDiagnoses(params)
        .then((res) => {
          context.commit("setDiagnoses", {
            data: res.data.data,
            reset: payload.reset,
          });
          resolve(res.data.data);
        })
        .catch((error) => reject(error));
    });
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
