import AdvisorOrganizationsRepository from "@/shared/api/Repositories/advisor/AdvisorOrganisationsRepository";
import PartnersRepository from "@/shared/api/Repositories/advisor/AdvisorPartnersRepository";
import TemplatesRepository from "@/shared/api/Repositories/TemplatesRepository";
import LinksRepository from "@/shared/api/Repositories/LinksRepository";
import AdvisorOrganisationsRepository from "@/shared/api/Repositories/advisor/AdvisorOrganisationsRepository";
import qs from "qs";
import { saveAs } from "file-saver";
import { formatOrganizationsStructure } from "../../../helper/formatOrganizationsStructure";
import { filterOrganisationsTreeview } from "../../../helper/filterOrganisationsTreeview";

const state = () => ({
  myOrganizations: {},
  addLinkModal: false,
  partners: {},
  employees: {},
  addLinkModalConfirm: false,
  uploadReportModal: { status: false, id: null },
  searchOrganisations: [],
  searchTreeviewOrganisations: [],
});

const getters = {
  myOrganizations: (state) => state.myOrganizations,
  addLinkModal: (state) => state.addLinkModal,
  partners: (state) => state.partners,
  employees: (state) => state.employees,
  addLinkModalConfirm: (state) => state.addLinkModalConfirm,
  uploadReportModal: (state) => state.uploadReportModal,
  searchOrganisations: (state) => state.searchOrganisations,
  searchTreeviewOrganisations: (state) => state.searchTreeviewOrganisations,
};

const mutations = {
  setMyOrganizations(state, payload) {
    if (payload.reset) {
      state.myOrganizations = {
        ...payload.data,
        isFiltered: payload.isFiltered,
      };
    } else {
      if (payload.isFiltered !== state.myOrganizations.isFiltered) {
        state.myOrganizations = {
          ...payload.data,
          isFiltered: payload.isFiltered,
        };
      } else {
        state.myOrganizations = {
          ...payload.data,
          isFiltered: payload.isFiltered,
          items: [...state.myOrganizations.items, ...payload.data.items],
        };
      }
    }
  },
  setAddLinkModal(state, payload) {
    state.addLinkModal = payload;
  },
  setPartners(state, payload) {
    if (payload.reset) {
      state.partners = payload.data;
    } else {
      state.partners = {
        ...payload.data,
        items: [...state.partners.items, ...payload.data.items],
      };
    }
  },
  setEmployees(state, payload) {
    if (payload.reset) {
      state.employees = payload.data;
    } else {
      state.employees = {
        ...payload.data,
        items: [...state.employees.items, ...payload.data.items],
      };
    }
  },
  setAddLinkModalConfirm(state, payload) {
    state.addLinkModalConfirm = payload;
  },
  setUploadReportModal(state, payload) {
    state.uploadReportModal = {
      status: payload.status,
      id: payload.id,
    };
  },
  setSearchOrganizations(state, payload) {
    state.searchOrganisations = payload;
  },
  setSearchTreeviewOrganisations(state, payload) {
    if (payload.reset) {
      state.searchTreeviewOrganisations = payload.data;
    } else {
      state.searchTreeviewOrganisations = {
        ...payload.data,
        items: [
          ...state.searchTreeviewOrganisations.items,
          ...payload.data.items,
        ],
      };
    }
  },
};

const actions = {
  getMyOrganizations(context, payload) {
    const params = {
      params: payload ? payload.params : null,
      paramsSerializer: (params) => qs.stringify(params, { encode: false }),
    };
    return new Promise((resolve, reject) => {
      AdvisorOrganizationsRepository.getMyOrganizations(params)
        .then((res) => {
          formatOrganizationsStructure(res.data.data.items, 1);
          context.commit("setMyOrganizations", {
            data: res.data.data,
            reset: payload.reset,
          });
          resolve(res.data.data);
        })
        .catch((error) => reject(error));
    });
  },
  getPartners(context, payload) {
    const params = {
      params: payload ? payload.params : null,
      paramsSerializer: (params) => qs.stringify(params, { encode: false }),
    };
    return new Promise((resolve, reject) => {
      PartnersRepository.getPartners(params)
        .then((res) => {
          const checkItems = res.data.data.items.map((element) => ({
            text: element.company_name,
            value: element.id,
          }));
          context.commit("setPartners", {
            data: { ...res.data.data, items: checkItems },
            reset: payload.reset,
          });
          resolve(res.data.data);
        })
        .catch((error) => reject(error));
    });
  },
  getEmployees(context, payload) {
    const params = {
      params: payload ? payload.params : null,
      paramsSerializer: (params) => qs.stringify(params, { encode: false }),
    };
    return new Promise((resolve, reject) => {
      AdvisorOrganizationsRepository.getEmployees(payload, params)
        .then((res) => {
          const checkItems = res.data.data.items.map((element) => ({
            text: element.full_name,
            value: element.id,
          }));
          context.commit("setEmployees", {
            data: { ...res.data.data, items: checkItems },
            reset: payload.reset,
          });
          resolve(res.data.data);
        })
        .catch((error) => reject(error));
    });
  },
  createLink(context, payload) {
    return new Promise((resolve, reject) => {
      LinksRepository.createLink(payload)
        .then((res) => {
          context.commit("setAddLinkModal", false);
          resolve(res.data.data);
        })
        .catch((error) => reject(error));
    });
  },
  uploadReport(context, payload) {
    return new Promise((resolve, reject) => {
      AdvisorOrganizationsRepository.uploadReport(payload.id, payload.fd)
        .then((res) => resolve(res.data.data))
        .catch((error) => reject(error));
    });
  },
  getTemplateMaReport() {
    return new Promise((resolve, reject) => {
      TemplatesRepository.getTemplateMaReport({ responseType: "arraybuffer" })
        .then((res) => {
          this.blob = new Blob([res.data], {
            type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;",
          });
          const fileName = `Шаблон отчета МС.xlsx`;
          saveAs(this.blob, fileName);
          resolve(res.data);
        })
        .catch((err) => reject(err));
    });
  },
  createFinalReport(context, payload) {
    return new Promise((resolve, reject) => {
      AdvisorOrganizationsRepository.createFinalReport(
        payload.id,
        payload.periods,
        {
          responseType: "arraybuffer",
        }
      )
        .then((res) => {
          this.blob = new Blob([res.data], {
            type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;",
          });
          const fileName = `Итоговый отчет ${payload.orgName}.xlsx`;
          saveAs(this.blob, fileName);
          resolve(res.data);
        })
        .catch((err) => reject(err));
    });
  },
  getSearchOrganisations(context, payload) {
    const params = {
      params: payload ? payload.params : null,
      paramsSerializer: (params) => qs.stringify(params, { encode: false }),
    };
    return new Promise((resolve, reject) => {
      AdvisorOrganisationsRepository.searchOrganisations(params)
        .then((res) => {
          if (payload?.searchMyOrganisations) {
            formatOrganizationsStructure(res.data.data.items, 1);
            context.commit("setMyOrganizations", {
              data: {
                ...res.data.data,
                items: res.data.data.items.map((searchedOrg) => {
                  return {
                    ...searchedOrg,
                    searchedOrganisation: true,
                    bLeft: 16,
                  };
                }),
              },
              reset: payload.reset,
              isFiltered: true,
            });
          } else {
            context.commit("setMyOrganizations", {
              data: res.data.data,
              reset: payload.reset,
              isFiltered: false,
            });
          }

          resolve(res.data.data);
        })
        .catch((error) => reject(error));
    });
  },
  getSearchTreeviewOrganisations(context, payload) {
    const params = {
      params: payload ? payload.params : null,
      paramsSerializer: (params) => qs.stringify(params, { encode: false }),
    };
    return new Promise((resolve, reject) => {
      AdvisorOrganisationsRepository.searchOrganisations(params)
        .then((res) => {
          if (payload?.isSearch) {
            const expandedItems = [];
            const newOrgs = res.data.data.items.map((organisation) => {
              return filterOrganisationsTreeview(organisation, expandedItems);
            });
            context.commit("setSearchTreeviewOrganisations", {
              data: { ...res.data.data, items: newOrgs },
              reset: payload.reset,
            });
            resolve(expandedItems);
          } else {
            context.commit("setSearchTreeviewOrganisations", {
              data: res.data.data,
              reset: payload.reset,
            });
            resolve();
          }
        })
        .catch((error) => reject(error));
    });
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
