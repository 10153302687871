import Service from "@/shared/api/Service";

const resource = "/advisor/organisations";
export default {
  getMyOrganizations(params) {
    return Service.get(`${resource}`, params);
  },
  createConsultation(params) {
    return Service.post(
      `${resource}/${params.organisation_id}/employees/${params.employee}/consultations`,
      params.data
    );
  },
  editConsultation(params) {
    return Service.put(
      `${resource}/${params.organisation_id}/employees/${params.employee}/consultations/${params.id}`,
      params.data
    );
  },
  getEmployees(payload, params) {
    return Service.get(`${resource}/${payload.id}/employees`, params);
  },
  uploadReport(id, data) {
    return Service.post(`${resource}/${id}/consultations/import`, data);
  },
  searchOrganisations(params) {
    return Service.get(`${resource}/search`, params);
  },
  createFinalReport(id, periods, params) {
    return Service.post(`${resource}/${id}/summary-report`, periods, params);
  },
  seacrhFlatOrganisations(params) {
    return Service.get(`${resource}/flat-search`, params);
  },
};
