import Service from "@/shared/api/Service";

const resource = "/partner/partner-reports";
export default {
  uploadReport(data) {
    return Service.post(`${resource}`, data);
  },
  getReports(params) {
    return Service.get(`${resource}`, params);
  },
  getReportDetail(id, params) {
    return Service.get(`${resource}/${id}`, params);
  },
  saveAndResendReport(id, data) {
    return Service.post(`${resource}/${id}/resubmit`, data);
  },
};
