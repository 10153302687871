import Vue from "vue";
import VueRouter from "vue-router";
import store from "@/shared/store";

Vue.use(VueRouter);

const AdminOrganizations = () => import("@/pages/admin/Organizations");
const AdminOrganization = () => import("@/pages/admin/Organization");
const AdminEditContact = () => import("@/pages/admin/EditContact");
const AdminMedicalAdvisors = () => import("@/pages/admin/MedicalAdvisors");
const AdminPartners = () => import("@/pages/admin/Partners");
const AdminReportsForm = () => import("@/pages/admin/ReportsForm");
const AdminOverdueRecords = () => import("@/pages/admin/OverdueRecords");
const AdminReportForm = () => import("@/pages/admin/ReportForm");
const AdminReportFormEdit = () => import("@/pages/admin/ReportFormEdit");

const AdvisorMyOrganizations = () => import("@/pages/advisor/MyOrganizations");
const AdvisorMyInsured = () => import("@/pages/advisor/MyInsured");
const AdvisorDirectoryPartners = () =>
  import("@/pages/advisor/DirectoryPartners");
const AdvisorReportsVerification = () =>
  import("@/pages/advisor/ReportsVerification");
const AdvisorReportDetail = () =>
  import("@/pages/advisor/ReportVerificationDetail");
const AdvisorInsuredDetail = () => import("@/pages/advisor/InsuredDetail");
const AdvisorFailedToCall = () => import("@/pages/advisor/FailedToCall");
const AdvisorReportLinks = () => import("@/pages/advisor/ReportLinks");

const PartnerInsured = () => import("@/pages/partner/Insured");
const PartnerInsuredDetail = () => import("@/pages/partner/InsuredDetail");
const PartnerReports = () => import("@/pages/partner/Reports");

const Login = () => import("@/pages/auth/Login");
const RestorePassword = () => import("@/pages/auth/RestorePassword");
const CreatePassword = () => import("@/pages/auth/CreatePassword");
const NotFound = () => import("@/pages/errors/NotFound");
const Charts = () => import("@/pages/Charts.vue");

const routes = [
  {
    path: "/admin/organizations",
    component: AdminOrganizations,
    meta: {
      layout: "DefaultLayout",
      auth: true,
      isAdmin: true,
      title: "Организации",
    },
  },
  {
    path: "/admin/organizations/:id",
    component: AdminOrganization,
    meta: {
      layout: "DefaultLayout",
      auth: true,
      isAdmin: true,
      title: "Организация",
    },
  },
  {
    path: "/admin/organizations/:id/contact",
    component: AdminEditContact,
    meta: {
      layout: "DefaultLayout",
      auth: true,
      isAdmin: true,
      title: "Редактирование ответственного",
    },
  },
  {
    path: "/admin/medical-advisors",
    component: AdminMedicalAdvisors,
    meta: {
      layout: "DefaultLayout",
      auth: true,
      isAdmin: true,
      title: "Мед советники",
    },
  },
  {
    path: "/admin/partners",
    component: AdminPartners,
    meta: {
      layout: "DefaultLayout",
      auth: true,
      isAdmin: true,
      title: "Партнеры",
    },
  },
  {
    path: "/admin/reports-form",
    component: AdminReportsForm,
    meta: {
      layout: "DefaultLayout",
      auth: true,
      isAdmin: true,
      title: "Формы отчетов",
    },
  },
  {
    path: "/admin/overdue-records",
    component: AdminOverdueRecords,
    meta: {
      layout: "DefaultLayout",
      auth: true,
      isAdmin: true,
      title: "Просроченные записи",
    },
  },
  {
    path: "/admin/reports-form/:id",
    component: AdminReportForm,
    meta: {
      layout: "DefaultLayout",
      auth: true,
      isAdmin: true,
      title: "Форма отчета",
    },
  },
  {
    path: "/admin/reports-form/:id/edit",
    component: AdminReportFormEdit,
    meta: {
      layout: "DefaultLayout",
      auth: true,
      isAdmin: true,
      title: "Редактирование формы отчета",
    },
  },
  {
    path: "/advisor/my-organizations",
    component: AdvisorMyOrganizations,
    meta: {
      layout: "DefaultLayout",
      auth: true,
      isAdvisor: true,
      title: "Мои организации",
    },
  },
  {
    path: "/advisor/my-organizations/report-links",
    component: AdvisorReportLinks,
    meta: {
      layout: "DefaultLayout",
      auth: true,
      isAdvisor: true,
      title: "Ссылки для отчета",
    },
  },
  {
    path: "/advisor/my-insured",
    component: AdvisorMyInsured,
    meta: {
      layout: "DefaultLayout",
      auth: true,
      isAdvisor: true,
      title: "Мои застрахованные",
    },
  },
  {
    path: "/advisor/my-insured/failed-to-call",
    component: AdvisorFailedToCall,
    meta: {
      layout: "DefaultLayout",
      auth: true,
      isAdvisor: true,
      title: "Не удалось дозвониться",
    },
  },
  {
    path: "/advisor/my-insured/:id",
    component: AdvisorInsuredDetail,
    meta: {
      layout: "DefaultLayout",
      auth: true,
      isAdvisor: true,
      title: "Детальная страница застрахованного",
    },
  },
  {
    path: "/advisor/directory-partners",
    component: AdvisorDirectoryPartners,
    meta: {
      layout: "DefaultLayout",
      auth: true,
      isAdvisor: true,
      title: "Справочник Партнеры",
    },
  },
  {
    path: "/advisor/directory-partners/reports-verification",
    component: AdvisorReportsVerification,
    meta: {
      layout: "DefaultLayout",
      auth: true,
      isAdvisor: true,
      title: "Отчеты на проверку",
    },
  },
  {
    path: "/advisor/directory-partners/reports-verification/:id",
    component: AdvisorReportDetail,
    meta: {
      layout: "DefaultLayout",
      auth: true,
      isAdvisor: true,
      title: "Отчет",
    },
  },
  {
    path: "/partner/insured",
    component: PartnerInsured,
    meta: {
      layout: "DefaultLayout",
      auth: true,
      isPartner: true,
      title: "Застрахованные",
    },
  },
  {
    path: "/partner/insured/:id",
    component: PartnerInsuredDetail,
    meta: {
      layout: "DefaultLayout",
      auth: true,
      isPartner: true,
      title: "Карточка застрахованного",
    },
  },
  {
    path: "/partner/reports",
    name: "partner-reports",
    component: PartnerReports,
    meta: {
      layout: "DefaultLayout",
      auth: true,
      isPartner: true,
      title: "Отчеты",
    },
  },
  {
    path: "/links/:id",
    redirect: (to) => {
      localStorage.setItem("link", to.path);
      return { path: "/partner/insured" };
    },
  },
  {
    path: "/sign-in",
    component: Login,
    meta: {
      layout: "AuthLayout",
      auth: false,
      title: "Авторизация",
    },
  },
  {
    path: "/restore-password",
    component: RestorePassword,
    meta: {
      layout: "AuthLayout",
      auth: false,
      title: "Восстановление пароля",
    },
  },
  {
    path: "/create-password",
    component: CreatePassword,
    meta: {
      layout: "AuthLayout",
      auth: false,
      title: "Заведение пароля",
    },
  },
  {
    path: "/404",
    name: "NotFound",
    component: NotFound,
    meta: {
      layout: "DefaultLayout",
      auth: false,
      title: "Страница не найдена",
    },
  },
  {
    path: "/charts",
    component: Charts,
    meta: {
      layout: "AuthLayout",
      auth: false,
      title: "Тестовые графики",
    },
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

/**
 * Метод, отвечающий за рендер меню в зависимости от роли
 * @returns {Promise<void>}
 */
const initMenu = async () => {
  const storage = JSON.parse(localStorage.getItem("alfa"));
  if (storage) {
    store.dispatch("renderMenu", storage.roles);
  }
};

initMenu();

router.afterEach((to) => {
  document.title = to.meta.title;
});

router.beforeEach((to, from, next) => {
  const isAuth = !!localStorage.getItem("csrf-token");
  const user = JSON.parse(localStorage.getItem("alfa"));
  if (isAuth) {
    if (to.meta.isAdmin && user.roles[0] === "admin") {
      next();
    } else if (to.meta.isAdvisor && user.roles[0] === "advisor") {
      next();
    } else if (to.meta.isPartner && user.roles[0] === "partner") {
      next();
    } else if (!to.matched.length && to.path !== "/") {
      next({ path: "/404" });
    } else if (to.fullPath === "/404") {
      next();
    } else {
      router.push(store.getters.homePage);
    }
  } else {
    if (!to.matched.length) {
      next("/sign-in");
    } else {
      next();
    }
  }
});

export default router;
