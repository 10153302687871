import Vue from "vue";
import Vuetify from "vuetify/lib/framework";
import VueMask from "v-mask";
Vue.use(VueMask);

Vue.use(Vuetify);

export default new Vuetify({
  treeShake: true,
  theme: {
    themes: {
      light: {
        primary: {
          base: "#CF0A2C",
          darken1: "#B22034",
          disable: true,
        },
      },
    },
  },
});
