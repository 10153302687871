import qs from "qs";
import dateHelper from "@/shared/helper/moment";

import EnsuredRepository from "@/shared/api/Repositories/EnsuredRepository";
import MedicalAdvisorsRepository from "@/shared/api/Repositories/MedicalAdvisorsRepository";
import CommunicationTypesRepository from "@/shared/api/Repositories/CommunicationTypesRepository";
import HealthGroupRepository from "@/shared/api/Repositories/HealthGroupRepository";
import DiagnosesRepository from "@/shared/api/Repositories/DiagnosesRepository";
import AdvisorOrganisationsRepository from "@/shared/api/Repositories/advisor/AdvisorOrganisationsRepository";
import StandardsRepository from "@/shared/api/Repositories/StandardsRepository";

const state = () => ({
  ensured: {},
  ensuredMedicalExaminations: {},
  ensuredConsultations: {},
  specialties: [],
  communicationTypes: [],
  diagnoses: [],
  healthGroup: [],
  editFocusGroupModal: false,
  changeDynamicModal: {
    status: false,
    dataSet: false,
    title: "",
    ticks: {
      stepSize: 1,
    },
    yScale: {
      in: 0,
      max: 0,
    },
  },
  healthStandards: null,
  disabilityPeriods: [],
});

const getters = {
  ensured: (state) => state.ensured,
  ensuredMedicalExaminations: (state) => state.ensuredMedicalExaminations,
  ensuredConsultations: (state) => state.ensuredConsultations,
  specialties: (state) => state.specialties,
  communicationTypes: (state) => state.communicationTypes,
  diagnoses: (state) => state.diagnoses,
  healthGroup: (state) => state.healthGroup,
  editFocusGroupModal: (state) => state.editFocusGroupModal,
  changeDynamicModal: (state) => state.changeDynamicModal,
  healthStandards: (state) => state.healthStandards,
  disabilityPeriods: (state) => state.disabilityPeriods,
};

const mutations = {
  setEnsured(state, payload) {
    state.ensured = payload;
  },
  setEnsuredMedicalExaminations(state, payload) {
    state.ensuredMedicalExaminations = payload;
  },
  setEnsuredConsultations(state, payload) {
    state.ensuredConsultations = payload;
  },
  setSpecialties(state, payload) {
    state.specialties = payload;
  },
  setCommunicationTypes(state, payload) {
    state.communicationTypes = payload.map((item) => {
      return { value: item, text: item };
    });
  },
  setDiagnoses(state, payload) {
    if (payload.reset) {
      state.diagnoses = {
        ...payload.data,
        items: payload.data.items.map((item) => {
          return {
            text: `${item.code} ${item.name}`,
            value: item.id,
          };
        }),
      };
    } else {
      state.diagnoses = {
        ...state.diagnoses,
        items: [
          ...state.diagnoses.items,
          ...payload.data.items.map((item) => {
            return {
              text: `${item.code} ${item.name}`,
              value: item.id,
            };
          }),
        ],
      };
    }
  },
  setHealthGroup(state, payload) {
    state.healthGroup = payload.map((item) => {
      return { value: item, text: item };
    });
  },
  setEditFocusGroupModal(state, payload) {
    state.editFocusGroupModal = payload;
  },
  setChangeDynamicModal(state, payload) {
    state.changeDynamicModal = payload;
  },

  setNewFocusGroupModal(state, index) {
    const newState = [...state.ensured.focus_group];
    newState.splice(Number(index), 1);
    state.ensured = { ...state.ensured, focus_group: newState };
  },
  setHealthStandards(state, payload) {
    state.healthStandards = payload;
  },
  setDisabilityPeriods(state, payload) {
    state.disabilityPeriods = payload;
  },
};

const actions = {
  getEnsuredInfo(context, payload) {
    return new Promise((resolve, reject) => {
      EnsuredRepository.getEnsured(payload.id)
        .then((response) => {
          let callStep = 1;
          const callAttemtps = response.data.data.call_attempts;
          if (callAttemtps.length < 3) {
            if (callAttemtps.length > 1) {
              callStep = response.data.data.call_attempts.length;
            }
          } else {
            const lastCallStatus = callAttemtps[callAttemtps.length - 1].status;
            if (lastCallStatus) {
              callStep = 4;
            } else {
              callStep = 3;
            }
          }
          context.commit("setEnsured", {
            ...response.data.data,
            callStep: callStep,
          });
          resolve(response.data.data);
        })
        .catch((error) => reject(error));
    });
  },
  getEnsuredMedicalExaminations(context, payload) {
    return new Promise((resolve, reject) => {
      EnsuredRepository.getEnsuredMedicalExaminations(payload.id)
        .then((response) => {
          context.commit("setEnsuredMedicalExaminations", response.data.data);
          resolve(response.data.data);
        })
        .catch((error) => reject(error));
    });
  },
  getEnsuredConsultations(context, payload) {
    return new Promise((resolve, reject) => {
      EnsuredRepository.getEnsuredConsultations(payload.id)
        .then((response) => {
          context.commit("setEnsuredConsultations", response.data.data);
          resolve(response.data.data);
        })
        .catch((error) => reject(error));
    });
  },
  getSpecialties(context) {
    return new Promise((resolve, reject) => {
      MedicalAdvisorsRepository.getSpecialties()
        .then((response) => {
          context.commit("setSpecialties", response.data.data);
          resolve(response.data.data);
        })
        .catch((error) => reject(error));
    });
  },
  getCommunicationTypes(context) {
    return new Promise((resolve, reject) => {
      CommunicationTypesRepository.getCommunicationTypes()
        .then((response) => {
          context.commit("setCommunicationTypes", response.data.data);
          resolve(response.data.data);
        })
        .catch((error) => reject(error));
    });
  },
  getDiagnoses(context, payload) {
    const params = {
      params: payload.params ? payload.params : null,
      paramsSerializer: (params) => qs.stringify(params, { encode: false }),
    };
    return new Promise((resolve, reject) => {
      DiagnosesRepository.getDiagnoses(params)
        .then((res) => {
          context.commit("setDiagnoses", {
            data: res.data.data,
            reset: payload.reset,
          });
          resolve(res.data.data);
        })
        .catch((error) => reject(error));
    });
  },
  createConsultation(context, payload) {
    return new Promise((resolve, reject) => {
      AdvisorOrganisationsRepository.createConsultation(payload)
        .then((res) => {
          resolve(res.data.data);
        })
        .catch((error) => reject(error));
    });
  },
  editConsultation(context, payload) {
    return new Promise((resolve, reject) => {
      AdvisorOrganisationsRepository.editConsultation(payload)
        .then((res) => {
          resolve(res.data.data);
        })
        .catch((error) => reject(error));
    });
  },
  getHealthGroups(context) {
    return new Promise((resolve, reject) => {
      HealthGroupRepository.getHealthGroups()
        .then((res) => {
          context.commit("setHealthGroup", res.data.data);
          resolve(res.data.data);
        })
        .catch((error) => reject(error));
    });
  },
  createFocusGroupForInsured(context, payload) {
    return new Promise((resolve, reject) => {
      EnsuredRepository.createFocusGroupForInsured(payload)
        .then((res) => {
          resolve(res.data.data);
        })
        .catch((error) => reject(error));
    });
  },
  deleteFocusGroup(context, payload) {
    return new Promise((resolve, reject) => {
      EnsuredRepository.deleteFocusGroup(payload)
        .then((res) => {
          resolve(res.data.data);
        })
        .catch((error) => reject(error));
    });
  },
  getHealthStandards(context) {
    return new Promise((resolve, reject) => {
      StandardsRepository.getHealthStandards()
        .then((response) => {
          context.commit("setHealthStandards", response.data.data);
          resolve(response);
        })
        .catch((error) => reject(error));
    });
  },
  updateComment(context, payload) {
    return new Promise((resolve, reject) => {
      EnsuredRepository.updateComment(payload)
        .then((res) => {
          resolve(res.data.data);
        })
        .catch((error) => reject(error));
    });
  },
  getEnsuredDisabilityPeriods(context, payload) {
    return new Promise((resolve, reject) => {
      EnsuredRepository.getEnsuredDisabilityPeriods(payload.id)
        .then((res) => {
          const disabilityPeriods = res.data.data.map((period) => {
            return {
              ...period.data,
              disability_start_date: dateHelper.dateFormat(
                period.data.disability_start_date
              ),
              disability_end_date: dateHelper.dateFormat(
                period.data.disability_end_date
              ),
              countOfDays: dateHelper.differenceInDays(
                period.data.disability_start_date,
                period.data.disability_end_date
              ),
            };
          });
          context.commit("setDisabilityPeriods", disabilityPeriods);
          resolve(res.data.data);
        })
        .catch((error) => reject(error));
    });
  },
  updateDisabilityPeriodsComment(context, payload) {
    return new Promise((resolve, reject) => {
      EnsuredRepository.updateDisabilityPeriodsComment(payload)
        .then((res) => {
          resolve(res.data.data);
        })
        .catch((error) => reject(error));
    });
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
