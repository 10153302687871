import Service from "@/shared/api/Service";

const resource = "/medical-advisors";
export default {
  getAdvisors(params) {
    return Service.get(`${resource}`, params);
  },
  blockMedicalAdvisor(payload) {
    return Service.post(`${resource}/${payload.medicalAdvisorId}/block`);
  },
  unBlockMedicalAdvisor(payload) {
    return Service.post(`${resource}/${payload.medicalAdvisorId}/unlock`);
  },
  addMedicalAdvisors(params) {
    return Service.post(`${resource}`, params);
  },
  getSpecialties() {
    return Service.get(`${resource}/specialties`);
  },
  getOverdueCalls(params) {
    return Service.get(`/medical-advisor/unsuccessfully-calls`, params);
  },
  getFocusGroup(params) {
    return Service.get(`/medical-advisor/focus-groups`, params);
  },
  prepareExportOverdueCalls(params) {
    return Service.post(
      `/medical-advisor/unsuccessfully-calls/export`,
      null,
      params
    );
  },
};
