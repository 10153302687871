import Service from "@/shared/api/Service";

const resource = "/auth";
export default {
  login(payload) {
    return Service.post(`${resource}/login`, payload);
  },
  me() {
    return Service.post(`${resource}/me`);
  },
  refresh() {
    return Service.post(`${resource}/refresh`);
  },
  createPassword(payload) {
    return Service.post(`${resource}/verification`, payload);
  },
  restorePassword(payload) {
    return Service.post(`${resource}/forgot-password`, payload);
  },
  resetPasswordWithCode(payload) {
    return Service.post(`${resource}/check-code`, payload);
  },
  resetPassword(payload) {
    return Service.post(`${resource}/reset-password`, payload);
  },
  logout(payload) {
    return Service.post(`${resource}/logout`, payload);
  },
};
