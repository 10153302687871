import Service from "@/shared/api/Service";

const resource = "/examples";
export default {
  getTemplateAddOrg(params) {
    return Service.get(`${resource}/organisations`, params);
  },
  getTemplateMaReport(params) {
    return Service.get(`${resource}/medical-advisors-report`, params);
  },
  getTemplatePartnerReport(organizationId, params) {
    return Service.get(`${resource}/partner-report/${organizationId}`, params);
  },
  getRules(organizationId, params) {
    return Service.get(
      `/filling-rules/partner-report/${organizationId}`,
      params
    );
  },
  getTemplateDisabilityPeriods(params) {
    return Service.get(`${resource}/disability-periods`, params);
  },
  getTemplateUploadEmployees(params) {
    return Service.get(`${resource}/employees`, params);
  },
};
