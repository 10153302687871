import Service from "@/shared/api/Service";

const resource = "/jobs";
export default {
  getJobStatus(id, params) {
    return Service.get(`${resource}/${id}`, params);
  },
  exportFile(payload) {
    return Service.get(`${resource}/${payload.id}/download`, payload.data);
  },
};
