import qs from "qs";
import { saveAs } from "file-saver";
import Vue from "vue";

import AdminOrganizationsRepository from "@/shared/api/Repositories/admin/AdminOrganizationsRepository";
import TemplatesRepository from "@/shared/api/Repositories/TemplatesRepository";
import ReportsFormRepository from "@/shared/api/Repositories/admin/AdminReportsFormRepository";

import { formatOrganizationsStructure } from "../../../helper/formatOrganizationsStructure";
const state = () => ({
  organizations: {},
  modalAddOrganizationState: false,
  addOrgInfo: {
    name: null,
    file: null,
    responsible_name: null,
    phone: null,
    work_phone: null,
  },
  modalAddReport: false,
  reportForms: {},
  searchOrganisations: [],
});
const getters = {
  organizations: (state) => state.organizations,
  modalAddOrganizationState: (state) => state.modalAddOrganizationState,
  modalAddReport: (state) => state.modalAddReport,
  reportForms: (state) => state.reportForms,
  searchOrganisations: (state) => state.searchOrganisations,
};
const mutations = {
  setOrganizations(state, payload) {
    if (payload.reset) {
      state.organizations = payload.data;
    } else {
      state.organizations = {
        ...payload.data,
        items: [...state.organizations.items, ...payload.data.items],
      };
    }
  },

  setAddOrgModalState(state, payload) {
    state.modalAddOrganizationState = payload;
  },

  setModalAddReport(state, payload) {
    state.modalAddReport = payload;
  },

  setReportForms(state, payload) {
    if (payload.reset) {
      state.reportForms = payload.data;
    } else {
      state.reportForms = {
        ...payload.data,
        items: [...state.reportForms.items, ...payload.data.items],
      };
    }
  },

  setRowData(state, payload) {
    const tempData = [...state.organizations.items];
    const reduceDataFunc = (data, updateAdvisorForChildren = false) => {
      for (const item of data) {
        const newAdvisors = [
          ...item.medical_advisors,
          ...payload.addedMedicalAdvisors,
        ];
        const uniqueAdvisors = [...newAdvisors].reduce((result, item) => {
          return result.find((advisor) => advisor.id === item.id)
            ? result
            : [...result, item];
        }, []);
        if (item.id === payload.id) {
          if (payload.addedMedicalAdvisors.length) {
            Vue.set(item, payload.key, newAdvisors);
            if (item.children.length) {
              reduceDataFunc(item.children, true);
            }
          } else {
            Vue.set(item, payload.key, payload.data[payload.key]);
          }
          break;
        } else if (item.id !== payload.id && updateAdvisorForChildren) {
          Vue.set(item, "medical_advisors", uniqueAdvisors);
          if (item.children.length) {
            reduceDataFunc(item.children, true);
          }
        } else {
          if (item.children.length) {
            reduceDataFunc(item.children);
          }
        }
      }
    };
    reduceDataFunc(tempData);
    state.organizations = {
      ...state.organizations,
      items: [...tempData],
    };
  },
  setSearchOrganizations(state, payload) {
    state.searchOrganisations = payload;
  },
};
const actions = {
  getOrganizations(context, payload) {
    const params = {
      params: payload ? payload.params : null,
      paramsSerializer: (params) => qs.stringify(params, { encode: false }),
    };
    return new Promise((resolve, reject) => {
      AdminOrganizationsRepository.getOrganizations(params)
        .then((res) => {
          const reduceDataFunc = (data, level) => {
            data.forEach((m) => {
              m.isExpand = false;
              m.isAdvisorsExpand = false;
              m.children = m.children || [];
              m.level = level;
              m.bLeft = level * 16;
              if (m.children.length) {
                reduceDataFunc(m.children, level + 1);
              }
            });
          };
          reduceDataFunc(res.data.data.items, 1);
          context.commit("setOrganizations", {
            data: res.data.data,
            reset: payload.reset,
          });
          resolve(res.data.data);
        })
        .catch((error) => reject(error));
    });
  },
  addOrganization(context, payload) {
    return new Promise((resolve, reject) => {
      AdminOrganizationsRepository.addOrganization(payload)
        .then((res) => resolve(res.data.data))
        .catch((error) => reject(error));
    });
  },
  getTemplateAddOrg() {
    return new Promise((resolve, reject) => {
      TemplatesRepository.getTemplateAddOrg({ responseType: "arraybuffer" })
        .then((res) => {
          this.blob = new Blob([res.data], {
            type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;",
          });
          saveAs(this.blob, "Шаблон списка сотрудников организации.xlsx");
          resolve(res.data);
        })
        .catch((err) => reject(err));
    });
  },
  getReportForms(context, payload) {
    const params = {
      params: payload ? payload.params : null,
      paramsSerializer: (params) => qs.stringify(params, { encode: false }),
    };
    return new Promise((resolve, reject) => {
      ReportsFormRepository.getReportsForm(params)
        .then((res) => {
          context.commit("setReportForms", {
            data: res.data.data,
            reset: payload.reset,
          });
          resolve(res.data.data);
        })
        .catch((error) => reject(error));
    });
  },
  updateOrganization(context, payload) {
    return new Promise((resolve, reject) => {
      AdminOrganizationsRepository.updateOrganization(payload)
        .then((res) => resolve(res.data.data))
        .catch((error) => reject(error));
    });
  },

  getSearchOrganisations(context, payload) {
    const params = {
      params: payload ? payload.params : null,
      paramsSerializer: (params) => qs.stringify(params, { encode: false }),
    };
    return new Promise((resolve, reject) => {
      AdminOrganizationsRepository.getSearchOrganizations(params)
        .then((res) => {
          if (payload?.searchMyOrganisations) {
            formatOrganizationsStructure(res.data.data.items, 1);
            context.commit("setOrganizations", {
              data: {
                ...res.data.data,
                items: res.data.data.items.map((searchedOrg) => {
                  return {
                    ...searchedOrg,
                    searchedOrganisation: true,
                  };
                }),
              },
              reset: payload.reset,
              isFiltered: true,
            });
          } else {
            context.commit("setOrganizations", {
              data: res.data.data,
              reset: payload.reset,
              isFiltered: false,
            });
          }
          resolve(res.data.data);
        })
        .catch((error) => reject(error));
    });
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
