import LinksRepository from "@/shared/api/Repositories/LinksRepository";
import qs from "qs";

const state = () => ({
  reportLinks: [],
});

const getters = {
  reportLinks: (state) => state.reportLinks,
};

const mutations = {
  setReportLinks(state, payload) {
    if (payload.reset) {
      state.reportLinks = payload.data;
    } else {
      state.reportLinks = {
        ...payload.data,
        items: [...state.reportLinks.items, ...payload.data.items],
      };
    }
  },
  setResendedLink(state, payload) {
    const newState = [...state.reportLinks.items];
    newState[payload.index] = {
      ...newState[payload.index],
      updated_at: payload.updated_at,
    };
    state.reportLinks = { ...state.reportLinks, items: newState };
  },
};

const actions = {
  getLinks(context, payload) {
    const params = {
      params: payload ? payload.params : null,
      paramsSerializer: (params) => qs.stringify(params, { encode: false }),
    };
    return new Promise((resolve, reject) => {
      LinksRepository.getLinks(params)
        .then((res) => {
          context.commit("setReportLinks", {
            data: res.data.data,
            reset: payload.reset,
          });
          resolve(res.data.data);
        })
        .catch((error) => reject(error));
    });
  },
  resendLink(context, payload) {
    return new Promise((resolve, reject) => {
      LinksRepository.resendLink(payload)
        .then((res) => {
          resolve(res.data.data);
        })
        .catch((error) => reject(error));
    });
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
