import Service from "@/shared/api/Service";

const resource = "/partner/ensured";
export default {
  insuredList(params) {
    return Service.get(`${resource}`, params);
  },
  getCurrentEnsured(params) {
    return Service.get(`${resource}/${params.id}`);
  },
  sendIndividualReport(payload) {
    return Service.post(
      `${resource}/${payload.id}/medical-examinations`,
      payload.data
    );
  },
  didNotComePmo(payload) {
    return Service.post(`${resource}/${payload.id}/hide`);
  },
};
