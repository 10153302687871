import qs from "qs";

import PartnersRepository from "@/shared/api/Repositories/PartnersRepository";
import CitiesRepository from "@/shared/api/Repositories/CitiesRepository";

const state = () => ({
  partners: {},
  modalBlockPartner: false,
  modalAddPartner: false,
  cities: [],
  modalConfirmAddPartner: false,
});

const getters = {
  partners: (state) => state.partners,
  modalBlockPartner: (state) => state.modalBlockPartner,
  modalAddPartner: (state) => state.modalAddPartner,
  cities: (state) => state.cities,
  modalConfirmAddPartner: (state) => state.modalConfirmAddPartner,
};

const mutations = {
  setPartners(state, payload) {
    if (payload.reset) {
      state.partners = payload.data;
    } else {
      state.partners = {
        ...payload.data,
        items: [...state.partners.items, ...payload.data.items],
      };
    }
  },
  setBlockPartner(state, payload) {
    state.modalBlockPartner = payload;
  },
  setAddPartner(state, payload) {
    state.modalAddPartner = payload;
  },
  setCities(state, payload) {
    state.cities = payload;
  },
  setAddModalConfirmPartner(state, payload) {
    state.modalConfirmAddPartner = payload;
  },
  setBlockedRow(state, index) {
    const newState = [...state.partners.items];
    newState[index] = {
      ...newState[index],
      user: {
        ...newState[index].user,
        is_blocked: !newState[index].user.is_blocked,
      },
    };
    state.partners = { ...state.partners, items: newState };
  },
  setExpandOrgs(state, index) {
    const checkItems = [...state.partners.items];
    checkItems[index] = {
      ...checkItems[index],
      expandOrgs: !checkItems[index].expandOrgs,
    };
    state.partners = {
      ...state.partners,
      items: checkItems,
    };
  },
};

const actions = {
  getPartners(context, payload) {
    const params = {
      params: payload ? payload.params : null,
      paramsSerializer: (params) => qs.stringify(params, { encode: false }),
    };
    return new Promise((resolve, reject) => {
      PartnersRepository.getPartners(params)
        .then((res) => {
          context.commit("setPartners", {
            data: {
              ...res.data.data,
              items: res.data.data.items.map((item) => {
                return {
                  ...item,
                  expandOrgs: false,
                };
              }),
            },
            reset: payload.reset,
          });
          resolve(res.data.data);
        })
        .catch((error) => reject(error));
    });
  },
  blockPartner(context, payload) {
    return new Promise((resolve, reject) => {
      PartnersRepository.blockPartner(payload)
        .then((res) => {
          context.commit("setBlockPartner", false);
          resolve(res.data.data);
        })
        .catch((error) => reject(error));
    });
  },
  unBlockPartner(context, payload) {
    return new Promise((resolve, reject) => {
      PartnersRepository.unBlockPartner(payload)
        .then((res) => {
          context.commit("setBlockPartner", false);
          resolve(res.data.data);
        })
        .catch((error) => reject(error));
    });
  },
  addPartner(context, payload) {
    return new Promise((resolve, reject) => {
      PartnersRepository.addPartner(payload)
        .then((res) => {
          context.commit("setAddPartner", false);
          resolve(res.data.data);
        })
        .catch((error) => reject(error));
    });
  },
  getCities(context, payload) {
    return new Promise((resolve, reject) => {
      CitiesRepository.getCities(payload)
        .then((res) => {
          context.commit("setCities", res.data.data);
          resolve(res.data.data);
        })
        .catch((error) => reject(error));
    });
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
