import qs from "qs";

import PartnerReportsRepository from "@/shared/api/Repositories/partner/PartnerReportsRepository";

const state = () => ({
  reports: {},
});

const getters = {
  reports: (state) => state.reports,
};

const mutations = {
  setReports(state, payload) {
    if (payload.reset) {
      state.reports = payload.data;
    } else {
      state.reports = {
        ...payload.data,
        items: [...state.reports.items, ...payload.data.items],
      };
    }
  },
};

const actions = {
  getReports(context, payload) {
    const params = {
      params: payload ? payload.params : null,
      paramsSerializer: (params) => qs.stringify(params, { encode: false }),
    };
    return new Promise((resolve, reject) => {
      PartnerReportsRepository.getReports(params)
        .then((res) => {
          context.commit("setReports", {
            data: res.data.data,
            reset: payload.reset,
          });
          resolve(res.data.data);
        })
        .catch((error) => reject(error));
    });
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
