export function getAdminMenu() {
  return [
    { title: "Организации", path: "/admin/organizations" },
    { title: "Мед. Советники", path: "/admin/medical-advisors" },
    { title: "Партнеры", path: "/admin/partners" },
    { title: "Формы отчетов", path: "/admin/reports-form" },
    { title: "Просроченные записи", path: "/admin/overdue-records" },
  ];
}

export function getMedicalAdvisorMenu() {
  return [
    { title: "Мои организации", path: "/advisor/my-organizations" },
    { title: "Мои застрахованные", path: "/advisor/my-insured" },
    { title: "Справочник “Партнеры”", path: "/advisor/directory-partners" },
  ];
}

export function getPartnerMenu() {
  return [
    { title: "Застрахованные", path: "/partner/insured" },
    { title: "Отчеты", path: "/partner/reports" },
  ];
}
