import Vue from "vue";
import Vuex from "vuex";
import user from "@/shared/store/modules/user";
import organizations from "@/shared/store/modules/admin/organizations";
import organizationDetail from "@/shared/store/modules/admin/organizationDetail";
import medicalAdvisors from "@/shared/store/modules/admin/medicalAdvisors";
import partners from "@/shared/store/modules/admin/partners";
import reportsForm from "@/shared/store/modules/admin/reportsForm";
import asyncTasks from "@/shared/store/modules/common/asyncTasks";
import myOrganizations from "@/shared/store/modules/advisor/myOrganizations";
import snackbar from "@/shared/store/modules/common/snackbar";
import insured from "@/shared/store/modules/advisor/insured";
import insures from "@/shared/store/modules/partners/partnerInsured";
import partnerInsuredDetail from "@/shared/store/modules/partners/partnerInsuredDetail";
import EnsuredDetail from "@/shared/store/modules/advisor/insuredDetail";
import directoryPartners from "@/shared/store/modules/advisor/directoryPartners";
import tryCall from "@/shared/store/modules/advisor/tryCall";
import overdue from "@/shared/store/modules/advisor/overdue";
import reportsVerification from "@/shared/store/modules/advisor/reportsVerification";
import reportsVerificationDetail from "@/shared/store/modules/advisor/reportsVerificationDetail";
import reportLinks from "@/shared/store/modules/advisor/reportLinks";
import reports from "@/shared/store/modules/partners/reports";
import reportDetail from "@/shared/store/modules/partners/reportDetail";
import overdueRecords from "@/shared/store/modules/admin/overdueRecords";
import partnerOrganisations from "@/shared/store/modules/partners/partnerOrganisations";
import partnerMedicalAdvisors from "@/shared/store/modules/partners/partnerMedicalAdvisors";
import exportReports from "@/shared/store/modules/common/exportReports";
import asyncApproveReport from "@/shared/store/modules/common/asyncApproveReport";
Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    user,
    /**
     * admin
     */
    organizations,
    organizationDetail,
    medicalAdvisors,
    partners,
    reportsForm,
    overdueRecords,
    /**
     * common
     */
    exportReports,
    asyncTasks,
    snackbar,
    /**
     * advisor
     */
    myOrganizations,
    insured,
    EnsuredDetail,
    directoryPartners,
    tryCall,
    overdue,
    reportsVerification,
    reportsVerificationDetail,
    reportLinks,
    /**
     * partners
     */
    insures,
    partnerInsuredDetail,
    reports,
    reportDetail,
    partnerOrganisations,
    partnerMedicalAdvisors,

    /**
     * Async
     */
    asyncApproveReport,
  },
});
