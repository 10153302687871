import Service from "@/shared/api/Service";

const resource = "/organisations";

export default {
  firedEmployee(payload) {
    return Service.post(
      `/advisor${resource}/${payload.organization}/employees/${payload.employeeId}/fired`
    );
  },
  unFiredEmployee(payload) {
    return Service.post(
      `/advisor${resource}/${payload.organization}/employees/${payload.employeeId}/unfired`
    );
  },
  getDiagnosesStatistic(payload) {
    return Service.get(`${resource}/${payload.id}/diagnoses-statistics`);
  },
};
