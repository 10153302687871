import MedicalAdvisorsRepository from "@/shared/api/Repositories/MedicalAdvisorsRepository";
import qs from "qs";

const state = () => ({
  medicalAdvisors: {},
  modalBlockMedicalAdvisor: false,
  modalAddMedicalAdvisorState: false,
  specialties: [],
  modalAddConfirmMedicalAdvisorState: false,
});
const getters = {
  medicalAdvisors: (state) => state.medicalAdvisors,
  modalBlockMedicalAdvisor: (state) => state.modalBlockMedicalAdvisor,
  modalAddMedicalAdvisorState: (state) => state.modalAddMedicalAdvisorState,
  specialties: (state) => state.specialties,
  modalAddConfirmMedicalAdvisorState: (state) =>
    state.modalAddConfirmMedicalAdvisorState,
};
const mutations = {
  setMedicalAdvisors(state, payload) {
    if (payload.reset) {
      state.medicalAdvisors = payload.data;
    } else {
      state.medicalAdvisors = {
        ...payload.data,
        items: [...state.medicalAdvisors.items, ...payload.data.items],
      };
    }
  },
  setBlockMedicalAdvisor(state, payload) {
    state.modalBlockMedicalAdvisor = payload;
  },
  setAddMedicalAdvisorState(state, payload) {
    state.modalAddMedicalAdvisorState = payload;
  },
  setSpecialties(state, payload) {
    state.specialties = payload;
  },
  setAddConfirmMedicalAdvisorState(state, payload) {
    state.modalAddConfirmMedicalAdvisorState = payload;
  },
  setExpandOrgs(state, index) {
    const checkItems = [...state.medicalAdvisors.items];
    checkItems[index] = {
      ...checkItems[index],
      expandOrgs: !checkItems[index].expandOrgs,
    };
    state.medicalAdvisors = {
      ...state.medicalAdvisors,
      items: checkItems,
    };
  },
  setBlockedRow(state, index) {
    const newState = [...state.medicalAdvisors.items];
    newState[index] = {
      ...newState[index],
      user: {
        ...newState[index].user,
        is_blocked: !newState[index].user.is_blocked,
      },
    };
    state.medicalAdvisors = { ...state.medicalAdvisors, items: newState };
  },
};
const actions = {
  getMedicalAdvisors(context, payload) {
    const params = {
      params: payload ? payload.params : null,
      paramsSerializer: (params) => qs.stringify(params, { encode: false }),
    };
    const getAdvisorFullName = (advisor) => {
      if (advisor) {
        if (advisor?.middle_name) {
          return `${advisor?.last_name} ${advisor?.first_name} ${advisor?.middle_name}`;
        } else {
          return `${advisor?.last_name} ${advisor?.first_name}`;
        }
      } else {
        return "—";
      }
    };
    return new Promise((resolve, reject) => {
      MedicalAdvisorsRepository.getAdvisors(params)
        .then((res) => {
          context.commit("setMedicalAdvisors", {
            data: {
              ...res.data.data,
              items: res.data.data.items.map((item) => {
                return {
                  ...item,
                  expandOrgs: false,
                  fullName: getAdvisorFullName(item),
                };
              }),
            },
            reset: payload.reset,
          });
          resolve(res.data.data);
        })
        .catch((error) => reject(error));
    });
  },
  blockMedicalAdvisor(context, payload) {
    return new Promise((resolve, reject) => {
      MedicalAdvisorsRepository.blockMedicalAdvisor(payload)
        .then((res) => {
          context.commit("setBlockMedicalAdvisor", false);
          resolve(res.data.data);
        })
        .catch((error) => reject(error));
    });
  },
  unBlockMedicalAdvisor(context, payload) {
    return new Promise((resolve, reject) => {
      MedicalAdvisorsRepository.unBlockMedicalAdvisor(payload)
        .then((res) => {
          context.commit("setBlockMedicalAdvisor", false);
          resolve(res.data.data);
        })
        .catch((error) => reject(error));
    });
  },
  addMedicalAdvisor(context, payload) {
    return new Promise((resolve, reject) => {
      MedicalAdvisorsRepository.addMedicalAdvisors(payload)
        .then((res) => {
          context.commit("setAddMedicalAdvisorState", false);
          resolve(res.data.data);
        })
        .catch((error) => reject(error));
    });
  },
  getSpecialties(context, payload) {
    return new Promise((resolve, reject) => {
      MedicalAdvisorsRepository.getSpecialties(payload)
        .then((res) => {
          context.commit("setSpecialties", res.data.data);
          resolve(res.data.data);
        })
        .catch((error) => reject(error));
    });
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
