<template>
  <AppLayout>
    <router-view />
    <v-snackbar
      v-model="snackbar.status"
      color="red accent-2"
      top
      right
    >
      {{ snackbar.info }}
    </v-snackbar>
  </AppLayout>
</template>
<script>
import "@/sass/overrides.sass";

import { mapGetters, mapMutations } from "vuex";

export default {
  name: "App",
  computed: {
    ...mapGetters({
      snackbar: "snackbar/snackbar",
    }),
  },
  methods: {
    ...mapMutations({}),
  },
};
</script>
