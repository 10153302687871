import router from "@/shared/router";

import { RepositoryFactory } from "@/shared/api/Repositories/RepositoryFactory";
// Импорт эндпоинтов для пользователя
const UserRepository = RepositoryFactory.get("user");
// Синглтон API
import Repository from "@/shared/api/Service";
import * as rolesMenu from "@/shared/helper/rolesMenu";
import store from "@/shared/store";

const state = () => ({
  menu: [],
  homePage: "",
  me: null,
});

const mutations = {
  setMenu(state, data) {
    state.menu = data;
  },
  setHomePage(state, data) {
    state.homePage = data;
  },
  setMe(state, data) {
    state.me = data;
  },
};

const getters = {
  menu: (state) => state.menu,
  homePage: (state) => state.homePage,
  me: (state) => state.me,
};

const actions = {
  login(context, payload) {
    return new Promise((resolve, reject) => {
      UserRepository.login(payload)
        .then((res) => {
          const { csrf_token, roles } = res.data.data;
          /**
           * Установка CSRF-токена в заголовки запросов
           */
          Repository.defaults.headers.common["csrf-token"] = csrf_token;
          /**
           * Запись данных в localStorage
           */
          localStorage.setItem("alfa", JSON.stringify({ ...res.data.data }));
          localStorage.setItem("csrf-token", csrf_token);
          context.dispatch("renderMenu", roles);
          if (localStorage.getItem("link")) {
            router.push(localStorage.getItem("link"));
          } else {
            router.push(context.getters.homePage);
          }
          resolve(res.data.data);
        })
        .catch((err) => {
          if (err.response.status === 401) {
            store.commit("snackbar/setSnackbar", {
              status: true,
              info: "Пользователь не авторизован",
              isSuccess: false,
            });
          }
          reject(err);
        });
    });
  },
  me(context) {
    return new Promise((resolve, reject) => {
      UserRepository.me()
        .then((res) => {
          context.commit("setMe", res.data.data);
          resolve(res.data.data);
        })
        .catch((err) => reject(err));
    });
  },
  logout() {
    return new Promise((resolve, reject) => {
      UserRepository.logout()
        .then((res) => {
          localStorage.removeItem("csrf-token");
          localStorage.removeItem("alfa");
          localStorage.removeItem("link");
          delete Repository.defaults.headers.common["csrf-token"];
          router.push("/sign-in");
          resolve(res.data.data);
        })
        .catch((err) => reject(err));
    });
  },
  renderMenu: async (context, roles) => {
    let menu = [];
    if (roles) {
      await roles.some((item) => {
        if (item === "admin") {
          menu = [...rolesMenu.getAdminMenu()];
          context.commit("setHomePage", "/admin/organizations");
        }
        if (item === "advisor") {
          menu = [...rolesMenu.getMedicalAdvisorMenu()];
          context.commit("setHomePage", "/advisor/my-organizations");
        }
        if (item === "partner") {
          menu = [...rolesMenu.getPartnerMenu()];
          context.commit("setHomePage", "/partner/insured");
        }
      });
      await context.commit("setMenu", menu);
    }
  },
  createPassword(context, payload) {
    return new Promise((resolve, reject) => {
      UserRepository.createPassword(payload)
        .then((res) => resolve(res.data.data))
        .catch((err) => reject(err));
    });
  },
  restorePassword(context, payload) {
    return new Promise((resolve, reject) => {
      UserRepository.restorePassword(payload)
        .then((res) => resolve(res.data.data))
        .catch((err) => reject(err));
    });
  },
  resetPasswordWithCode(context, payload) {
    return new Promise((resolve, reject) => {
      UserRepository.resetPasswordWithCode(payload)
        .then((res) => resolve(res.data.data))
        .catch((err) => reject(err));
    });
  },
  resetPassword(context, payload) {
    return new Promise((resolve, reject) => {
      UserRepository.resetPassword(payload)
        .then((res) => resolve(res.data.data))
        .catch((err) => reject(err));
    });
  },
};

export default {
  state,
  getters,
  mutations,
  actions,
};
