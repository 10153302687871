import axios from "axios";
import UserRepository from "@/shared/api/Repositories/UserRepository";
import router from "@/shared/router";
import store from "@/shared/store";

let refreshing = false;

const repeatInterval = () =>
  new Promise((resolve) => {
    const interval = setInterval(() => {
      if (!refreshing) {
        resolve();
        clearInterval(interval);
      }
    }, 100);
  });

class Service {
  static instance;

  constructor() {
    this._client = axios.create({
      baseURL: process.env.VUE_APP_BASE_URL,
      withCredentials: true,
    });
    this.resolvedInterceptor = this.resolvedInterceptor.bind(this);
    this.rejectedInterceptor = this.rejectedInterceptor.bind(this);
    this.removeCSRFToken = this.removeCSRFToken.bind(this);
    this._client.interceptors.response.use(
      this.resolvedInterceptor,
      this.rejectedInterceptor
    );
    this.setCSRFToken();

    Service.instance = this;
  }

  resolvedInterceptor(response) {
    return Promise.resolve(response);
  }

  async rejectedInterceptor(error) {
    const isAuth = !!localStorage.getItem("csrf-token");

    const { status, config, data } = error.response;
    switch (status) {
      case 401: {
        const originalRequest = config;
        const isRefreshTokenRequest = originalRequest.url === "/auth/refresh";
        const isLoginRequest = originalRequest.url === "/auth/login";

        if (!isRefreshTokenRequest) {
          if (!isLoginRequest) {
            if (!refreshing) {
              refreshing = true;
              return this.refresh(originalRequest);
            } else {
              await repeatInterval();
              return await this.repeatRequest(originalRequest);
            }
          }
        } else {
          localStorage.removeItem("csrf-token");
          localStorage.removeItem("alfa");
          this.removeCSRFToken();
          await router.push("/sign-in");
        }
        break;
      }
      // TODO разобраться с 403 с переходами
      case 403: {
        if (data.error.code === "errors.blocked") {
          store.commit("snackbar/setSnackbar", {
            status: true,
            info: "Пользователь заблокирован",
            isSuccess: false,
          });
          if (isAuth) {
            localStorage.removeItem("csrf-token");
            localStorage.removeItem("alfa");
            this.removeCSRFToken();
            router.push("/sign-in");
          }
        }
        if (data.error.code === "errors.access_denied") {
          if (isAuth) {
            localStorage.removeItem("csrf-token");
            localStorage.removeItem("alfa");
            this.removeCSRFToken();
            router.push("/sign-in");
          } else {
            router.push("/sign-in");
          }
        }
        break;
      }
      default:
        break;
    }
    return Promise.reject(error);
  }

  static getInstance() {
    if (Service.instance) {
      return Service.instance;
    }
    return new Service();
  }

  setCSRFToken() {
    const csrf_token = localStorage.getItem("csrf-token");

    if (csrf_token) {
      this._client.defaults.headers.common["csrf-token"] = csrf_token;
    }
  }

  removeCSRFToken() {
    delete this._client.defaults.headers.common["csrf-token"];
  }

  refresh(originalRequest) {
    return new Promise((resolve, reject) => {
      UserRepository.refresh()
        .then((response) => {
          const { csrf_token } = response.data.data;
          localStorage.setItem("csrf-token", csrf_token);
          this.setCSRFToken();
          refreshing = false;
          this.repeatRequest(originalRequest)
            .then((res) => resolve(res))
            .catch((err) => reject(err));
        })
        .catch(() => {
          localStorage.removeItem("csrf-token");
          localStorage.removeItem("alfa");
          this.removeCSRFToken();
          refreshing = false;
          router.push("/sign-in");
        });
    });
  }
  repeatRequest = async (originalRequest) =>
    new Promise((resolve, reject) => {
      originalRequest.headers["csrf-token"] =
        localStorage.getItem("csrf-token");

      this._client(originalRequest)
        .then((data) => resolve(data))
        .catch((error) => reject(error));
    });
}

export default Service.getInstance()._client;
