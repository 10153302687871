import qs from "qs";
import dateHelper from "@/shared/helper/moment";

import AdminReportsFormRepository from "@/shared/api/Repositories/admin/AdminReportsFormRepository";

const state = () => ({
  reportsForm: {},
  reportForm: {},
  modalConfirmReportsFormState: false,
  confirmReportFormData: {},
});

const getters = {
  reportsForm: (state) => state.reportsForm,
  reportForm: (state) => state.reportForm,
  modalConfirmReportsFormState: (state) => state.modalConfirmReportsFormState,
  confirmReportFormData: (state) => state.confirmReportFormData,
};

const mutations = {
  setReportsForm(state, payload) {
    if (payload.reset) {
      state.reportsForm = {
        ...payload.data,
      };
      if (payload.data.items) {
        state.reportsForm.items = payload.data.items.map((item) => {
          return {
            ...item,
            isOrganisationExpand: false,
            created_at: dateHelper.dateFormat(item.created_at),
            updated_at: dateHelper.dateFormat(item.updated_at),
          };
        });
      }
    } else {
      state.reportsForm = {
        ...payload.data,
        items: [
          ...state.reportsForm.items,
          ...payload.data.items.map((item) => {
            return {
              ...item,
              isOrganisationExpand: false,
              created_at: dateHelper.dateFormat(item.created_at),
              updated_at: dateHelper.dateFormat(item.updated_at),
            };
          }),
        ],
      };
    }
  },
  setReportForm(state, payload) {
    state.reportForm = payload;
  },
  setModalConfirmReportsFormState(state, payload) {
    state.modalConfirmReportsFormState = payload;
  },
  setConfirmReportFormData(state, payload) {
    state.confirmReportFormData = payload;
  },
};

const actions = {
  getReportsForm(context, payload) {
    const params = {
      params: payload ? payload.params : null,
      paramsSerializer: (params) => qs.stringify(params, { encode: false }),
    };
    return new Promise((resolve, reject) => {
      AdminReportsFormRepository.getReportsForm(params)
        .then((res) => {
          context.commit("setReportsForm", {
            data: res.data.data,
            reset: payload.reset,
          });
          resolve(res.data.data);
        })
        .catch((error) => reject(error));
    });
  },
  getReportForm(context, payload) {
    return new Promise((resolve, reject) => {
      AdminReportsFormRepository.getReportForm(payload)
        .then((res) => {
          context.commit("setReportForm", res.data.data);
          resolve(res.data.data);
        })
        .catch((error) => reject(error));
    });
  },
  createReportForm(context, payload) {
    return new Promise((resolve, reject) => {
      AdminReportsFormRepository.createReportForm(payload)
        .then((res) => {
          context.commit("setModalConfirmReportsFormState", true);
          context.commit("setConfirmReportFormData", {
            type: "POST",
            data: res.data.data,
          });
          resolve(res.data.data);
        })
        .catch((error) => reject(error));
    });
  },
  editReportForm(context, payload) {
    return new Promise((resolve, reject) => {
      AdminReportsFormRepository.editReportForm(payload)
        .then((res) => {
          context.commit("setModalConfirmReportsFormState", true);
          context.commit("setConfirmReportFormData", {
            type: "PUT",
            data: res.data.data,
          });
          resolve(res.data.data);
        })
        .catch((error) => reject(error));
    });
  },
  checkReportFormName(context, payload) {
    return new Promise((resolve, reject) => {
      AdminReportsFormRepository.checkReportFormName(payload)
        .then((res) => {
          resolve(res.data.data);
        })
        .catch((error) => reject(error));
    });
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
