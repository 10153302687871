import InsuredRepository from "@/shared/api/Repositories/partner/PartnerInsuredRepository";
import EnsuredRepository from "@/shared/api/Repositories/EnsuredRepository";
import DiagnosesRepository from "@/shared/api/Repositories/DiagnosesRepository";
import qs from "qs";

const state = () => ({
  currentInsured: {},
  ensuredMedicalExaminations: {},
  diagnoses: [],
});

const getters = {
  currentInsured: (state) => state.currentInsured,
  ensuredMedicalExaminations: (state) => state.ensuredMedicalExaminations,
  diagnoses: (state) => state.diagnoses,
};

const mutations = {
  setCurrentInsured(state, payload) {
    state.currentInsured = payload;
  },
  setEnsuredMedicalExaminations(state, payload) {
    state.ensuredMedicalExaminations = payload;
  },
  setDiagnoses(state, payload) {
    if (payload.reset) {
      state.diagnoses = {
        ...payload.data,
        items: payload.data.items.map((item) => {
          return {
            text: `${item.code} ${item.name}`,
            value: item.id,
          };
        }),
      };
    } else {
      state.diagnoses = {
        ...state.diagnoses,
        items: [
          ...state.diagnoses.items,
          ...payload.data.items.map((item) => {
            return {
              text: `${item.code} ${item.name}`,
              value: item.id,
            };
          }),
        ],
      };
    }
  },
};

const actions = {
  getCurrentInsured(context, payload) {
    return new Promise((resolve, reject) => {
      InsuredRepository.getCurrentEnsured(payload)
        .then((res) => {
          context.commit("setCurrentInsured", res.data.data);
          resolve(res.data.data);
        })
        .catch((error) => reject(error));
    });
  },
  getEnsuredMedicalExaminations(context, payload) {
    return new Promise((resolve, reject) => {
      EnsuredRepository.getEnsuredMedicalExaminations(payload.id)
        .then((response) => {
          context.commit("setEnsuredMedicalExaminations", [
            ...response.data.data.map((medicalExamination) => {
              return {
                ...medicalExamination,
                data: {
                  ...medicalExamination.data,
                  examination_date: medicalExamination.date_of_examination,
                },
              };
            }),
          ]);
          resolve(response.data.data);
        })
        .catch((error) => reject(error));
    });
  },
  sendIndividualReport(context, payload) {
    return new Promise((resolve, reject) => {
      InsuredRepository.sendIndividualReport(payload)
        .then((response) => {
          resolve(response.data.data);
        })
        .catch((error) => reject(error));
    });
  },
  getDiagnoses(context, payload) {
    const params = {
      params: payload.params ? payload.params : null,
      paramsSerializer: (params) => qs.stringify(params, { encode: false }),
    };
    return new Promise((resolve, reject) => {
      DiagnosesRepository.getDiagnoses(params)
        .then((res) => {
          context.commit("setDiagnoses", {
            data: res.data.data,
            reset: payload.reset,
          });
          resolve(res.data.data);
        })
        .catch((error) => reject(error));
    });
  },
  didNotComePmo(context, payload) {
    return new Promise((resolve, reject) => {
      InsuredRepository.didNotComePmo(payload)
        .then((res) => resolve(res.data.data))
        .catch((error) => reject(error));
    });
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
