import TryCallRepository from "@/shared/api/Repositories/TryCallRepository";
import qs from "qs";

const state = () => ({
  callStatuses: [],
});

const getters = {
  callStatuses: (state) => state.callStatuses,
};

const mutations = {
  setCallStatuses(state, payload) {
    state.callStatuses = payload;
  },
};

const actions = {
  getCallStatuses(context, payload) {
    const params = {
      params: payload ? payload.params : null,
      paramsSerializer: (params) => qs.stringify(params, { encode: false }),
    };
    return new Promise((resolve, reject) => {
      TryCallRepository.getCallStatuses(params)
        .then((res) => {
          context.commit(
            "setCallStatuses",

            res.data.data.map((item) => {
              return {
                text: item,
                value: item,
              };
            })
          );
          resolve(res.data.data);
        })
        .catch((error) => reject(error));
    });
  },
  setStatusRefused(context, payload) {
    return new Promise((resolve, reject) => {
      TryCallRepository.setStatusRefused(payload)
        .then((res) => {
          resolve(res.data.data);
        })
        .catch((error) => reject(error));
    });
  },

  setStatusRescheduled(context, payload) {
    return new Promise((resolve, reject) => {
      TryCallRepository.setStatusRescheduled(payload)
        .then((res) => {
          resolve(res.data.data);
        })
        .catch((error) => reject(error));
    });
  },
  setStatusFailedToCall(context, payload) {
    return new Promise((resolve, reject) => {
      TryCallRepository.setStatusFailedToCall(payload)
        .then((res) => {
          resolve(res.data.data);
        })
        .catch((error) => reject(error));
    });
  },
  createFirstCallAttempt(context, payload) {
    return new Promise((resolve, reject) => {
      TryCallRepository.createFirstCallAttempt(payload)
        .then((res) => {
          resolve(res.data.data);
        })
        .catch((error) => reject(error));
    });
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
