import Service from "@/shared/api/Service";

const resource = "/links";

export default {
  getLinks(params) {
    return Service.get("/links", params);
  },
  resendLink(link) {
    return Service.post(`/links/${link}/resend`);
  },
  createLink(params) {
    return Service.post(`${resource}`, params);
  },
  getInfoFromLink(id, params) {
    return Service.get(`${resource}/${id}`, params);
  },
};
