import AsyncTasksRepository from "@/shared/api/Repositories/AsyncTasksRepository";

const state = () => ({
  approveReportData: {
    status: false,
    id: null,
  },
  action: null,
  isSuccess: false,
  dataForDisplay: {
    title: "",
    text: "",
  },
  updateData: () => {},
});

const getters = {
  dataForDisplay: (state) => state.dataForDisplay,
  approveReportData: (state) => state.approveReportData,
  isSuccess: (state) => state.isSuccess,
  action: (state) => state.action,
  updateData: (state) => state.updateData,
};

const mutations = {
  setApprovalModalState(state, payload) {
    state.approveReportData = payload;
  },
  setDataForDisplay(state, payload) {
    state.dataForDisplay = payload;
  },
  setAction(state, payload) {
    state.action = payload;
  },
  setSuccessState(state, payload) {
    state.isSuccess = payload;
  },
  setUpdateData(state, payload) {
    state.updateData = payload;
  },
};

const actions = {
  getJobStatus(context, payload) {
    return new Promise((resolve, reject) => {
      AsyncTasksRepository.getJobStatus(payload.id, null)
        .then((res) => resolve(res.data.data))
        .catch((error) => reject(error));
    });
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
