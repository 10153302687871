export function filterOrganisationsTreeview(organisation, expandedItems) {
  if (organisation.open || organisation?.match) {
    expandedItems.push(organisation.id);
    if (organisation?.children?.length && organisation?.open) {
      return {
        ...organisation,
        children: organisation.children.map((organisation) => {
          return filterOrganisationsTreeview(organisation, expandedItems);
        }),
      };
    } else {
      if (organisation.match) {
        return { ...organisation, children: null };
      } else {
        return organisation;
      }
    }
  }
}
