import qs from "qs";
import PartnerOrganisationsRepository from "@/shared/api/Repositories/partner/PartnerOrganisationsRepository";
import { filterOrganisationsTreeview } from "../../../helper/filterOrganisationsTreeview";

const state = () => ({
  organisations: {},
  searchOrganisations: {},
});

const getters = {
  organisations: (state) => state.organisations,
  searchOrganisations: (state) => state.searchOrganisations,
};

const mutations = {
  setOrganisations(state, payload) {
    if (payload.reset) {
      state.organisations = payload.data;
    } else {
      state.organisations = {
        ...payload.data,
        items: [...state.organisations.items, ...payload.data.items],
      };
    }
  },
  setSearchOrganizations(state, payload) {
    if (payload.reset) {
      state.searchOrganisations = payload.data;
    } else {
      state.searchOrganisations = {
        ...payload.data,
        items: [...state.searchOrganisations.items, ...payload.data.items],
      };
    }
  },
};

const actions = {
  getOrganizations(context, payload) {
    const params = {
      params: payload ? payload.params : null,
      paramsSerializer: (params) => qs.stringify(params, { encode: false }),
    };
    return new Promise((resolve, reject) => {
      PartnerOrganisationsRepository.getOrganisations(params)
        .then((res) => {
          context.commit("setOrganisations", {
            data: res.data.data,
            reset: payload.reset,
          });
          resolve(res.data.data);
        })
        .catch((error) => reject(error));
    });
  },

  getSearchOrganizations(context, payload) {
    const params = {
      params: payload ? payload.params : null,
      paramsSerializer: (params) => qs.stringify(params, { encode: false }),
    };
    return new Promise((resolve, reject) => {
      PartnerOrganisationsRepository.getSearchOrganisations(params)
        .then((res) => {
          if (payload?.params?.filter?.search) {
            const expandedItems = [];
            const newOrgs = res.data.data.items.map((organisation) => {
              return filterOrganisationsTreeview(organisation, expandedItems);
            });
            context.commit("setSearchOrganizations", {
              data: { ...res.data.data, items: newOrgs },
              reset: payload.reset,
            });
            resolve(expandedItems);
          } else {
            context.commit("setSearchOrganizations", {
              data: res.data.data,
              reset: payload.reset,
            });
            resolve(res.data.data);
          }
        })
        .catch((error) => reject(error));
    });
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
