import Service from "@/shared/api/Service";

const resource = "/partner/organisations";
export default {
  getOrganisations(params) {
    return Service.get(`${resource}`, params);
  },
  getSearchOrganisations(params) {
    return Service.get(`${resource}/search`, params);
  },
};
