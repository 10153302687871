import qs from "qs";
import PartnerMedicalAdvisorRepository from "@/shared/api/Repositories/partner/PartnerMedicalAdvisorRepository";
const state = () => ({
  medicalAdvisors: {},
});

const getters = {
  medicalAdvisors: (state) => state.medicalAdvisors,
};

const mutations = {
  setMedicalAdvisors(state, payload) {
    state.medicalAdvisors = payload;
    if (payload.reset) {
      state.medicalAdvisors = payload.data;
    } else {
      state.medicalAdvisors = {
        ...payload.data,
        items: [...state.medicalAdvisors.items, ...payload.data.items],
      };
    }
  },
};

const actions = {
  getMedicalAdvisors(context, payload) {
    const params = {
      params: payload ? payload.params : null,
      paramsSerializer: (params) => qs.stringify(params, { encode: false }),
    };
    const getAdvisorFullName = (advisor) => {
      if (advisor) {
        if (advisor?.middle_name) {
          return `${advisor?.last_name} ${advisor?.first_name} ${advisor?.middle_name}`;
        } else {
          return `${advisor?.last_name} ${advisor?.first_name}`;
        }
      } else {
        return "—";
      }
    };
    return new Promise((resolve, reject) => {
      PartnerMedicalAdvisorRepository.getMedicalAdvisors(params)
        .then((res) => {
          context.commit("setMedicalAdvisors", {
            data: {
              ...res.data.data,
              items: res.data.data.items.map((item) => {
                return {
                  ...item,
                  fullName: getAdvisorFullName(item),
                };
              }),
            },
            reset: payload.reset,
          });
          resolve(res.data.data);
        })
        .catch((error) => reject(error));
    });
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
