const state = () => ({
  snackbar: {
    status: false,
    info: null,
    isSuccess: false,
  },
});

const getters = {
  snackbar: (state) => state.snackbar,
};

const mutations = {
  setSnackbar(state, data) {
    state.snackbar = data;
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  getters,
};
