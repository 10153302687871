import Vue from "vue";
import { saveAs } from "file-saver";
import AsyncTasksRepository from "@/shared/api/Repositories/AsyncTasksRepository";

const state = () => ({
  /** Отображение Модалки со статусом */
  modalStatusJob: {
    status: false,
    id: null,
  },

  /** Отображение Модалки с результатом импорта файла */
  modalJobResult: {
    status: false,
    isSuccess: false,
  },

  /** Данные для повторной отправки запроса */
  dataForRepeat: {},

  /** Данные для отображения в модалке */
  dataForDisplay: {
    title: "",
    text: "",
  },

  /** Ошибки при импорте файла */
  errors: [],
  action: "",

  updateData: null,
  jsonTable: [],
  jsonErrorRowsTable: [],
  jsonTableName: null,
  errorRowsIndexes: [],
  maxErrorsCount: null,
  invalidRowsCount: null,
  status: null,
});

const getters = {
  /** Модальное окно со статусом импорта */
  modalStatusJob: (state) => state.modalStatusJob,

  /** Модальное окно с результатом импорта */
  modalJobResult: (state) => state.modalJobResult,
  dataForRepeat: (state) => state.dataForRepeat,
  dataForDisplay: (state) => state.dataForDisplay,
  errors: (state) => state.errors,
  action: (state) => state.action,
  updateData: (state) => state.updateData,
  jsonTable: (state) => state.jsonTable,
  jsonErrorRowsTable: (state) => state.jsonErrorRowsTable,
  jsonTableName: (state) => state.jsonTableName,
  errorRowsIndexes: (state) => state.errorRowsIndexes,
  maxErrorsCount: (state) => state.maxErrorsCount,
  invalidRowsCount: (state) => state.invalidRowsCount,
  status: (state) => state.status,
};

const mutations = {
  setStatusModalState(state, payload) {
    state.modalStatusJob = payload;
  },
  /** Модальное окно с результатом импорта */
  setResultModalState(state, payload) {
    state.modalJobResult = payload;
  },
  setDataForRepeat(state, payload) {
    state.dataForRepeat = payload;
  },
  setDataForDisplay(state, payload) {
    state.dataForDisplay = payload;
  },
  setErrors(state, payload) {
    state.errors = payload;
  },
  setAction(state, payload) {
    state.action = payload;
  },
  setUpdateData(state, payload) {
    state.updateData = payload;
  },
  setJsonTableData(state, payload) {
    state.jsonTable = payload;
  },
  setJsonErrorRowsTableData(state, payload) {
    state.jsonErrorRowsTable = payload;
  },
  setJsonTableDataName(state, payload) {
    state.jsonTableName = payload;
  },
  setErrorRowsIndexes(state, payload) {
    state.errorRowsIndexes = payload;
  },
  setEditData(state, payload) {
    if (state.maxErrorsCount) {
      const checkErrorRowsTable = [...state.jsonErrorRowsTable];
      Vue.delete(state.errors[payload.index + 2], payload.key);
      Vue.set(checkErrorRowsTable[payload.index], payload.key, payload.value);
      state.jsonErrorRowsTable = checkErrorRowsTable;
      const checkTable = [...state.jsonTable];
      checkTable[checkErrorRowsTable[payload.index]] =
        checkErrorRowsTable[payload.index];
      state.jsonTable = checkTable;
    } else {
      const checkTable = [...state.jsonTable];
      Vue.delete(state.errors[payload.index + 2], payload.key);
      Vue.set(checkTable[payload.index], payload.key, payload.value);
      state.jsonTable = checkTable;
    }
  },
  setErrorsMaxCount(state, payload) {
    state.maxErrorsCount = payload;
  },
  setInvalidRowsCount(state, payload) {
    state.invalidRowsCount = payload;
  },
  setStatus(state, payload) {
    state.status = payload;
  },
};

const actions = {
  getJobStatus(context, payload) {
    return new Promise((resolve, reject) => {
      AsyncTasksRepository.getJobStatus(payload.id, null)
        .then((res) => resolve(res.data.data))
        .catch((error) => reject(error));
    });
  },
  downloadReport(context, payload) {
    AsyncTasksRepository.exportFile({
      data: { responseType: "arraybuffer" },
      id: payload.id,
    }).then((res) => {
      this.blob = new Blob([res.data], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;",
      });
      saveAs(
        this.blob,
        `${payload.fileName.split(".xlsx")[0]} с ошибками.xlsx`
      );
    });
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
