import qs from "qs";

import AdminOverdueRecords from "@/shared/api/Repositories/admin/AdminOverdueRecords";

const state = () => ({
  overdueRecords: {},
});
const getters = {
  overdueRecords: (state) => state.overdueRecords,
};
const mutations = {
  setOverdueRecords(state, payload) {
    if (payload.reset) {
      state.overdueRecords = payload.data;
    } else {
      state.overdueRecords = {
        ...payload.data,
        items: [...state.overdueRecords.items, ...payload.data.items],
      };
    }
  },
};
const actions = {
  getOverdueRecords(context, payload) {
    const params = {
      params: payload ? payload.params : null,
      paramsSerializer: (params) => qs.stringify(params, { encode: false }),
    };
    return new Promise((resolve, reject) => {
      AdminOverdueRecords.getOverdueRecords(params)
        .then((res) => {
          context.commit("setOverdueRecords", {
            data: res.data.data,
            reset: payload.reset,
          });
          resolve(res.data.data);
        })
        .catch((error) => reject(error));
    });
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
