import Service from "@/shared/api/Service";

const resource = "/advisor/partner-reports";

export default {
  getPartnerReports(params) {
    return Service.get(`${resource}`, params);
  },
  getReportDetail(id, params) {
    return Service.get(`${resource}/${id}`, params);
  },
  rejectReport(id, data) {
    return Service.post(`${resource}/${id}/reject`, data);
  },
  acceptReport(id) {
    return Service.post(`${resource}/${id}/accept`);
  },
  updatePartnerComment(payload) {
    return Service.put(
      `${resource}/${payload.params.partnerReport}/comment`,
      payload.data
    );
  },
};
