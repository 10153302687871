import qs from "qs";
import { saveAs } from "file-saver";
import MedicalAdvisorsRepository from "@/shared/api/Repositories/MedicalAdvisorsRepository";
import AsyncTasksRepository from "@/shared/api/Repositories/AsyncTasksRepository";

const state = () => ({
  overdueCalls: {},
});
const getters = {
  overdueCalls: (state) => state.overdueCalls,
};
const mutations = {
  setOverdueCalls(state, payload) {
    if (payload.reset) {
      state.overdueCalls = payload.data;
    } else {
      state.overdueCalls = {
        ...payload.data,
        items: [...state.overdueCalls.items, ...payload.data.items],
      };
    }
  },
};
const actions = {
  getOverdueCalls(context, payload) {
    const params = {
      params: payload ? payload.params : null,
      paramsSerializer: (params) => qs.stringify(params, { encode: false }),
    };
    return new Promise((resolve, reject) => {
      MedicalAdvisorsRepository.getOverdueCalls(params)
        .then((res) => {
          context.commit("setOverdueCalls", {
            data: res.data.data,
            reset: payload.reset,
          });
          resolve(res.data.data);
        })
        .catch((error) => reject(error));
    });
  },

  prepareExportOverdueCalls(context, payload) {
    return new Promise((resolve, reject) => {
      const params = {
        params: payload ? payload : null,
        paramsSerializer: (params) => qs.stringify(params, { encode: false }),
      };
      MedicalAdvisorsRepository.prepareExportOverdueCalls(params)
        .then((res) => {
          resolve(res.data.data);
        })
        .catch((error) => reject(error));
    });
  },
  exportOverdueCalls(context, payload) {
    return new Promise((resolve, reject) => {
      AsyncTasksRepository.exportFile({
        data: { responseType: "arraybuffer" },
        id: payload.id,
      })
        .then((res) => {
          this.blob = new Blob([res.data], {
            type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;",
          });
          saveAs(this.blob, "Список не дозвонившихся.xlsx");
        })
        .catch((error) => reject(error));
    });
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
