import Service from "@/shared/api/Service";

const resource = "/focus-groups";

export default {
  createFocusGroup(payload) {
    return Service.post(`${resource}`, payload);
  },
  editFocusGroup(payload) {
    return Service.put(`${resource}/${payload.id}`, payload.data);
  },
  deleteFocusGroup(payload) {
    return Service.delete(`${resource}/${payload.id}`);
  },
  checkFocusGroup(payload) {
    return Service.post(`${resource}/check`, payload);
  },
};
