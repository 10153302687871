import moment from "moment";
import "moment/locale/ru";

export default {
  /**
   * Форматирование даты к нужному формату
   * @param {string} date - дата
   * @returns {string|string}
   */
  dateFormat(date) {
    return date ? moment(date).format("DD.MM.YYYY") : "—";
  },
  /**
   * Проверка даты на соответствие нужному формату
   * @param {string} date - дата
   * @returns {boolean}
   */
  isDateValid(date) {
    return moment(date, "DD.MM.YYYY").isValid();
  },
  /**
   * Вычисляет number в формате количества лет
   * @param {number} number - количество лет
   * @returns {string|string}
   */
  durationByNumber(number) {
    return number ? moment.duration(number, "years").humanize() : "—";
  },
  /**
   * Вычисляет разницу между date и сегодняшним днем в годах
   * @param {string} date - дата
   * @param {boolean} humanize - нужно ли использовать humanize
   * @returns {string|string} - возвращает количество лет, пройденных с начала даты
   */
  durationByDate(date, humanize = true) {
    const diff = moment().diff(date, "years");
    if (humanize) {
      return diff ? moment.duration(diff, "years").humanize() : "—";
    } else {
      return moment.duration(diff, "years");
    }
  },
  /**
   * Вычисляет разницу между dateBegin и dateEnd в днях
   * @param {string} dateBegin - дата периода
   * @param {string} dateEnd - дата конца периода
   * @returns {number|string}
   */
  differenceInDays(dateBegin, dateEnd) {
    return dateBegin && dateEnd
      ? moment(dateEnd).diff(moment(dateBegin), "days")
      : "—";
  },
  isSameOrAfterDate(now, date) {
    return moment(now).isSameOrAfter(date);
  },
};
