import AdvisorPartnerReportsRepository from "@/shared/api/Repositories/advisor/AdvisorPartnerReportsRepository";
import AdvisorOrganisationsRepository from "@/shared/api/Repositories/advisor/AdvisorOrganisationsRepository";
import qs from "qs";

const state = () => ({
  partnerReports: {},
  rejectReportModal: false,
  acceptReportModal: false,
  searchOrganisations: [],
});

const getters = {
  partnerReports: (state) => state.partnerReports,
  rejectReportModal: (state) => state.rejectReportModal,
  acceptReportModal: (state) => state.acceptReportModal,
  searchOrganisations: (state) => state.searchOrganisations,
};

const mutations = {
  setPartnerReports(state, payload) {
    if (payload.reset) {
      state.partnerReports = payload.data;
    } else {
      state.partnerReports = {
        ...payload.data,
        items: [...state.partnerReports.items, ...payload.data.items],
      };
    }
  },
  setReportItem(state, index) {
    const newState = [...state.partnerReports.items];
    newState.splice(Number(index), 1);
    state.partnerReports = { ...state.partnerReports, items: newState };
  },
  setRejectReportModal(state, payload) {
    state.rejectReportModal = payload;
  },
  setAcceptReportModal(state, payload) {
    state.acceptReportModal = payload;
  },
  setSearchOrganizations(state, payload) {
    if (payload.reset) {
      state.searchOrganisations = payload.data;
    } else {
      state.searchOrganisations = {
        ...payload.data,
        items: [...state.searchOrganisations.items, ...payload.data.items],
      };
    }
  },
};

const actions = {
  getPartnerReports(context, payload) {
    const params = {
      params: payload ? payload.params : null,
      paramsSerializer: (params) => qs.stringify(params, { encode: false }),
    };
    return new Promise((resolve, reject) => {
      AdvisorPartnerReportsRepository.getPartnerReports(params)
        .then((res) => {
          context.commit("setPartnerReports", {
            data: res.data.data,
            reset: payload.reset,
          });
          resolve(res.data.data);
        })
        .catch((error) => reject(error));
    });
  },
  rejectReport(context, payload) {
    return new Promise((resolve, reject) => {
      AdvisorPartnerReportsRepository.rejectReport(payload.id, payload.data)
        .then((res) => {
          context.commit("setRejectReportModal", false);
          resolve(res.data.data);
        })
        .catch((error) => reject(error));
    });
  },
  acceptReport(context, payload) {
    return new Promise((resolve, reject) => {
      AdvisorPartnerReportsRepository.acceptReport(payload.id)
        .then((res) => {
          context.commit("setAcceptReportModal", false);
          resolve(res.data.data);
        })
        .catch((error) => reject(error));
    });
  },
  getSearchOrganisations(context, payload) {
    const params = {
      params: payload ? payload.params : null,
      paramsSerializer: (params) => qs.stringify(params, { encode: false }),
    };
    return new Promise((resolve, reject) => {
      AdvisorOrganisationsRepository.searchOrganisations(params)
        .then((res) => {
          context.commit("setSearchOrganizations", {
            data: res.data.data,
            reset: payload.reset,
          });
          resolve(res.data.data);
        })
        .catch((error) => reject(error));
    });
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
