import qs from "qs";
import { saveAs } from "file-saver";
import AdminOrganizationsRepository from "@/shared/api/Repositories/admin/AdminOrganizationsRepository";
import MedicalAdvisorsRepository from "@/shared/api/Repositories/MedicalAdvisorsRepository";
import ResponsibleRepository from "@/shared/api/Repositories/ResponsibleRepository";
import TemplatesRepository from "@/shared/api/Repositories/TemplatesRepository";
import ProfessionRepository from "@/shared/api/Repositories/ProfessionRepository";
import AsyncTasksRepository from "@/shared/api/Repositories/AsyncTasksRepository";

const state = () => ({
  organization: {},
  employees: {},
  advisors: [],
  modalFireEmployeeState: false,
  modalDetachAdvisorState: false,
  modalAttachAdvisorState: false,
  advisorsForAttaching: {},
  professions: {},
});
const getters = {
  organization: (state) => state.organization,
  employees: (state) => state.employees,
  advisors: (state) => state.advisors,
  modalFireEmployeeState: (state) => state.modalFireEmployeeState,
  modalDetachAdvisorState: (state) => state.modalDetachAdvisorState,
  modalAttachAdvisorState: (state) => state.modalAttachAdvisorState,
  advisorsForAttaching: (state) => state.advisorsForAttaching,
  professions: (state) => state.professions,
};
const mutations = {
  setOrganization(state, payload) {
    state.organization = payload;
  },
  setEmployees(state, payload) {
    if (payload.reset) {
      state.employees = payload.data;
    } else {
      state.employees = {
        ...payload.data,
        items: [...state.employees.items, ...payload.data.items],
      };
    }
  },
  setAdvisors(state, payload) {
    state.advisors = payload;
  },
  setFireEmployeeState(state, payload) {
    state.modalFireEmployeeState = payload;
  },
  setDetachAdvisorState(state, payload) {
    state.modalDetachAdvisorState = payload;
  },
  setAttachAdvisorState(state, payload) {
    state.modalAttachAdvisorState = payload;
  },
  setAdvisorsForAttaching(state, payload) {
    if (payload.reset) {
      state.advisorsForAttaching = payload.data;
    } else {
      state.advisorsForAttaching = {
        ...payload.data,
        items: [...state.advisorsForAttaching.items, ...payload.data.items],
      };
    }
  },
  setProfessions(state, payload) {
    if (payload.reset) {
      state.professions = payload.data;
    } else {
      state.professions = {
        ...payload.data,
        items: [...state.professions.items, ...payload.data.items],
      };
    }
  },
};
const actions = {
  getOrganization(context, payload) {
    return new Promise((resolve, reject) => {
      AdminOrganizationsRepository.getOrganization(payload)
        .then((res) => {
          context.commit("setOrganization", res.data.data);
          resolve(res.data.data);
        })
        .catch((error) => reject(error));
    });
  },
  getOrgEmployees(context, payload) {
    const params = {
      params: payload ? payload.params : null,
      paramsSerializer: (params) => qs.stringify(params, { encode: false }),
    };
    return new Promise((resolve, reject) => {
      AdminOrganizationsRepository.getEmployees(payload.id, params)
        .then((res) => {
          context.commit("setEmployees", {
            data: res.data.data,
            reset: payload.reset,
          });
          resolve(res.data.data);
        })
        .catch((error) => reject(error));
    });
  },
  getOrgAdvisors(context, payload) {
    const params = {
      params: payload ? payload.params : null,
      paramsSerializer: (params) => qs.stringify(params, { encode: false }),
    };
    return new Promise((resolve, reject) => {
      AdminOrganizationsRepository.getAdvisors(payload.id, params)
        .then((res) => {
          context.commit("setAdvisors", res.data.data);
          resolve(res.data.data);
        })
        .catch((error) => reject(error));
    });
  },
  firedEmployee(context, payload) {
    return new Promise((resolve, reject) => {
      AdminOrganizationsRepository.firedEmployee(payload)
        .then((res) => {
          context.state.organization = {
            ...context.state.organization,
            employees_count: context.state.organization.employees_count - 1,
          };
          context.commit("setFireEmployeeState", false);
          resolve(res.data.data);
        })
        .catch((error) => reject(error));
    });
  },

  unfiredEmployee(context, payload) {
    return new Promise((resolve, reject) => {
      AdminOrganizationsRepository.unfiredEmployee(payload)
        .then((res) => {
          context.state.organization = {
            ...context.state.organization,
            employees_count: context.state.organization.employees_count + 1,
          };
          context.commit("setFireEmployeeState", false);
          resolve(res.data.data);
        })
        .catch((error) => reject(error));
    });
  },
  getAdvisorsForAttaching(context, payload) {
    const params = {
      params: payload ? payload.params : null,
      paramsSerializer: (params) => qs.stringify(params, { encode: false }),
    };
    return new Promise((resolve, reject) => {
      MedicalAdvisorsRepository.getAdvisors(params)
        .then((res) => {
          context.commit("setAdvisorsForAttaching", {
            data: res.data.data,
            reset: payload.reset,
          });
          resolve(res.data.data);
        })
        .catch((error) => reject(error));
    });
  },
  attachAdvisors(context, payload) {
    return new Promise((resolve, reject) => {
      AdminOrganizationsRepository.attachAdvisors(payload)
        .then((res) => {
          context.commit("setAttachAdvisorState", false);
          resolve(res.data.data);
        })
        .catch((error) => reject(error));
    });
  },
  // Редактирование ответственного
  editResponsible(context, payload) {
    return new Promise((resolve, reject) => {
      ResponsibleRepository.editResponsible(payload)
        .then((res) => resolve(res.data.data))
        .catch((error) => reject(error));
    });
  },
  createResponsible(context, payload) {
    return new Promise((resolve, reject) => {
      AdminOrganizationsRepository.createResponsible(payload)
        .then((res) => resolve(res.data.data))
        .catch((error) => reject(error));
    });
  },
  detachAdvisor(context, payload) {
    return new Promise((resolve, reject) => {
      AdminOrganizationsRepository.detachAdvisor(payload)
        .then((res) => {
          context.commit("setDetachAdvisorState", false);
          resolve(res.data.data);
        })
        .catch((error) => reject(error));
    });
  },
  detachAdvisorRecursive(context, payload) {
    return new Promise((resolve, reject) => {
      AdminOrganizationsRepository.detachAdvisorRecursive(payload)
        .then((res) => {
          context.commit("setDetachAdvisorState", false);
          resolve(res.data.data);
        })
        .catch((error) => reject(error));
    });
  },
  uploadDisabilityReport(context, payload) {
    return new Promise((resolve, reject) => {
      AdminOrganizationsRepository.uploadDisabilityReport(
        payload.id,
        payload.fd
      )
        .then((res) => resolve(res.data.data))
        .catch((error) => reject(error));
    });
  },
  getTemplateDisabilityReport() {
    return new Promise((resolve, reject) => {
      TemplatesRepository.getTemplateDisabilityPeriods({
        responseType: "arraybuffer",
      })
        .then((res) => {
          this.blob = new Blob([res.data], {
            type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;",
          });
          const fileName = `Шаблон отчета о ВН.xlsx`;
          saveAs(this.blob, fileName);
          resolve(res.data);
        })
        .catch((err) => reject(err));
    });
  },
  getProfessions(context, payload) {
    const params = {
      params: payload ? payload.params : null,
      paramsSerializer: (params) => qs.stringify(params, { encode: false }),
    };
    return new Promise((resolve, reject) => {
      ProfessionRepository.getProfessions(params)
        .then((res) => {
          context.commit("setProfessions", {
            data: res.data.data,
            reset: payload.reset,
          });
          resolve(res.data.data);
        })
        .catch((error) => reject(error));
    });
  },
  uploadEmployees(context, payload) {
    return new Promise((resolve, reject) => {
      AdminOrganizationsRepository.uploadEmployees(payload.id, payload.fd)
        .then((res) => resolve(res.data.data))
        .catch((error) => reject(error));
    });
  },
  getTemplateUploadEmployees() {
    return new Promise((resolve, reject) => {
      TemplatesRepository.getTemplateUploadEmployees({
        responseType: "arraybuffer",
      })
        .then((res) => {
          this.blob = new Blob([res.data], {
            type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;",
          });
          const fileName = `Шаблон импорта сотрудников.xlsx`;
          saveAs(this.blob, fileName);
          resolve(res.data);
        })
        .catch((err) => reject(err));
    });
  },
  createOrganizationStructure(context, payload) {
    return new Promise((resolve, reject) => {
      AdminOrganizationsRepository.createOrganizationStructure(payload)
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => reject(err));
    });
  },
  deleteOrganizationStructure(context, payload) {
    return new Promise((resolve, reject) => {
      AdminOrganizationsRepository.deleteOrganizationStructure(payload)
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => reject(err));
    });
  },
  prepareExportStructure(context, payload) {
    return new Promise((resolve, reject) => {
      AdminOrganizationsRepository.prepareExportStructure({
        orgId: payload.id,
      })
        .then((res) => {
          resolve(res.data.data);
        })
        .catch((error) => reject(error));
    });
  },
  exportStructure(context, payload) {
    return new Promise((resolve, reject) => {
      AsyncTasksRepository.exportFile({
        data: { responseType: "arraybuffer" },
        id: payload.id,
      })
        .then((res) => {
          this.blob = new Blob([res.data], {
            type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;",
          });
          saveAs(this.blob, "Структура организации.xlsx");
        })
        .catch((error) => reject(error));
    });
  },
  uploadTransferEmployeesReport(context, payload) {
    return new Promise((resolve, reject) => {
      AdminOrganizationsRepository.uploadTransferEmployeesReport({
        orgId: payload.id,
        data: payload.fd,
      })
        .then((res) => resolve(res.data.data))
        .catch((error) => reject(error));
    });
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
