import UserRepository from "@/shared/api/Repositories/UserRepository";
import AdminOrganizationsRepository from "@/shared/api/Repositories/admin/AdminOrganizationsRepository";

const repositories = {
  user: UserRepository,
  organizations: AdminOrganizationsRepository,
};

export const RepositoryFactory = {
  get: (name) => repositories[name],
};
